/* eslint-disable no-undef */
import { PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreModule } from '@workbench/core/core.module';
import { MonitorRequestInterceptor } from '@workbench/core/interceptors/monitor-request.interceptor';
import { PortalAuthInterceptor } from '@workbench/core/interceptors/portal-auth.interceptor';
import { SharedModule } from '@workbench/shared/shared.module';
import { metaReducers, reducers } from '@workbench/state';
import { AccountEffects } from '@workbench/state/account/account.effects';
import { CausalAnalysisEffects } from '@workbench/state/causal-analysis/causal-analysis.effects';
import { LibraryEffects } from '@workbench/state/library/library.effects';
import { ModelApiEffects } from '@workbench/state/model-api/model-api.effects';
import { GraphEffects } from '@workbench/state/model-builder/graph.effects';
import { ImportEffects } from '@workbench/state/model-builder/import-model.effects';
import { ModelBuilderEffects } from '@workbench/state/model-builder/model-builder.effects';
import { PortalEffects } from '@workbench/state/model-builder/portal.effects';
import { OptionsEffects } from '@workbench/state/options/options.effects';
import { ReasoningEffects } from '@workbench/state/reasoning/reasoning.effects';
import { AppConfigService, configFactory } from 'app-config.service';
import { environment } from 'environments/environment';
import { provideBootstrapEffects } from 'ngrx-effects-utils';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';

// Azure AD Config inspired from the following microsoft sample:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v2-samples/angular11-b2c-sample

export function msalInstanceFactory(config: AppConfigService): IPublicClientApplication {
  const { azureAdClientId, azureAdDomain, azureAdSignUpSignInPolicyId } = config.getConfig();

  return new PublicClientApplication({
    auth: {
      authority: azureAdSignUpSignInPolicyId,
      clientId: azureAdClientId,
      knownAuthorities: [azureAdDomain],
      postLogoutRedirectUri: '/',
      redirectUri: '/signin-oidc',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE(), // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function msalInterceptorConfigFactory(
  config: AppConfigService,
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  // TODO: Check if we need to add the API endpoints here
  // protectedResourceMap.set(config.getConfig().apiBaseUrl, apiScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function msalGuardConfigFactory(config: AppConfigService): MsalGuardConfiguration {
  const { azureAdScopes } = config.getConfig();

  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: azureAdScopes },
    loginFailedRoute: 'login-failed',
  };
}

function loggerCallback(logLevel: LogLevel, message: string): void {
  // Uncomment to see the MSAL logs
  // console.log(`[MSAL]\t[${LogLevel[logLevel]}]\t${message}`);
}

function isIE(): boolean {
  return (
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1
  );
}

@NgModule({
  bootstrap: [RootComponent, MsalRedirectComponent],
  declarations: [RootComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    EffectsModule.forRoot([]),
    HttpClientModule,
    MsalModule,
    RootRoutingModule,
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      connectInZone: true,
    }),
  ],
  providers: [
    // Defer Effects initialization until the app configuration is loaded
    provideBootstrapEffects([
      AccountEffects,
      CausalAnalysisEffects,
      GraphEffects,
      ImportEffects,
      LibraryEffects,
      ModelApiEffects,
      ModelBuilderEffects,
      OptionsEffects,
      PortalEffects,
      ReasoningEffects,
    ]),
    AppConfigService,
    MsalBroadcastService,
    MsalGuard,
    MsalService,
    {
      deps: [AppConfigService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: configFactory,
    },
    {
      deps: [AppConfigService],
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      deps: [AppConfigService],
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      deps: [AppConfigService],
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PortalAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MonitorRequestInterceptor, multi: true },
  ],
})
export class RootModule {}

export function getBaseHref(platformLocation: PlatformLocation): string {
  const baseUrl = platformLocation.getBaseHrefFromDOM();

  if (baseUrl) {
    return baseUrl;
  }

  return '/';
}

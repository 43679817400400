import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PortalApiService } from '@workbench/common/services/api/portal-api.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { fetchUserInfo, fetchUserInfoFault, fetchUserInfoSuccess } from './account.actions';

@Injectable()
export class AccountEffects {
  public readonly fetchUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserInfo),
      switchMap(() =>
        this.portalApiService.fetchUserInfo().pipe(
          map(({ fullName, guid, id, userName, role }) =>
            fetchUserInfoSuccess({ fullName, guid, id, userName, role }),
          ),
          catchError(() =>
            of(
              fetchUserInfoFault({
                message: `An error occurred during the user information fetching`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly fetchUserInfoFault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fetchUserInfoFault),
        map(({ message }) => this.notificationService.error(message)),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly portalApiService: PortalApiService,
  ) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { modelApiActions } from '../model-api/model-api.actions';
import { setLibraryModelTags, updateLibraryModels } from './library.actions';

@Injectable()
export class LibraryEffects {
  public readonly setGenericTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchGenericTagsSucceeded),
      map(({ data, guid: id, version }) =>
        setLibraryModelTags({ key: `${id}@${version}`, tagging: data }),
      ),
    ),
  );

  public readonly updateLibraryInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelTreeSucceeded),
      filter(({ response }) => response.submodels.length > 0),
      map(({ response }) => {
        const updates = new Set(
          [...response.updates.controlled, ...response.updates.uncontrolled].map(x => x.id),
        );

        return updateLibraryModels({
          models: response.submodels.map(x => ({
            asset: x.storage_model_asset,
            comment: x.model_comment,
            folder: x.storage_model_folder,
            guid: x.storage_model_guid,
            latest: updates.has(x.submodel_id) === false,
            name: x.storage_model_name,
            tag: x.model_tag,
            version: x.model_version,
          })),
        });
      }),
    ),
  );

  constructor(private readonly actions$: Actions) {}
}

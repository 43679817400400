import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { fetchUserInfoSuccess } from './account.actions';

export { AccountState, Permission, accountReducer };

interface AccountState {
  permissions: Permission[];
  role: Role | ''; // Role literal identifier
  roleName: string; // Role name
  userFullName: string;
  userGuid: string;
  userId: number;
  userName: string;
}

const initialState = (): AccountState => ({
  permissions: [],
  role: '',
  roleName: '',
  userFullName: '',
  userGuid: '',
  userId: 0,
  userName: '',
});

const accountReducer = createReducer(
  initialState(),
  on(
    fetchUserInfoSuccess,
    produce((state, { fullName, guid, id, userName, role }) => {
      state.userFullName = fullName;
      state.userGuid = guid;
      state.userId = id;
      state.userName = userName;
      state.permissions = getPermissionsByRole(role.shortForm as Role);
      state.role = role.shortForm as Role;
      state.roleName = role.name;
    }),
  ),
);

type Role = 'GA' | 'HV' | 'S'; // Role literal identifier
type Permission =
  | 'canAccessReasoning'
  | 'canDownloadModel'
  | 'canDownloadFlatFile'
  | 'canSaveModel';

// TODO:
// This is a temporary solution to get permissions by role.
// In the future, we will get permissions from the server.
function getPermissionsByRole(role: Role): Permission[] {
  switch (role) {
    case 'HV':
      return [];
  }

  return ['canAccessReasoning', 'canDownloadModel', 'canDownloadFlatFile', 'canSaveModel'];
}

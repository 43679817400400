import { APP_BOOTSTRAP_LISTENER, Inject, InjectionToken, Provider, Type } from '@angular/core';
import { EffectSources } from '@ngrx/effects';

/**
 * This set of utility functions defers ngrx Effects to be initialized
 * until APP_INITIALIZER is fulfilled and application configuration loaded.
 * This prevents dependent services (API services) to be initialized
 * because they are relying on the URLs provided by the JSON configuration file
 */

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effects: Type<unknown>[], sources: EffectSources) {
  return (): void => {
    effects.forEach(effect => sources.addEffects(effect));
  };
}

export function createInstances(...instances: unknown[]): unknown[] {
  return instances;
}

export function provideBootstrapEffects(effects: Type<unknown>[]): Provider {
  return [
    effects,
    { provide: BOOTSTRAP_EFFECTS, deps: effects, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
    },
  ];
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Option } from '@workbench/common/models/option';
import { AppConfigService } from 'app-config.service';
import { map, Observable } from 'rxjs';
import { ModelFile } from '../../models/model-file.model';
import { BaseApi } from './base-api';

interface LookupOption {
  id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LookupCategory: { id: string; name: string; code: string };
  name: string;
}

interface UserInfo {
  fullName: string;
  guid: string;
  id: number;
  userName: string;
  role: { id: number; name: string; shortForm: string };
}

@Injectable({
  providedIn: 'root',
})
export class PortalApiService extends BaseApi {
  constructor(appConfig: AppConfigService, httpClient: HttpClient) {
    super(appConfig.getConfig().portalApiBaseUrl, httpClient);
  }

  public fetchModelVersions(
    id: string,
  ): Observable<{ version: string; fileTag: { tag: string; comment: string } | null }[]> {
    return this.get<{
      data: { version: string; fileTag: { tag: string; comment: string } | null }[];
    }>(`get/file-versions/${id}?all=true`).pipe(map(({ data }) => data));
  }

  public fetchModelData(guid: string, version?: string): Observable<unknown[]> {
    const monitor = { on: true as const, title: 'Fetching the MFM model data' };
    const url = version ? `download/file/${guid}?version=${version}` : `download/file/${guid}`;

    return this.get(url, monitor);
  }

  public fetchModelFile(guid: string, version?: string): Observable<ModelFile> {
    const monitor = { on: true as const, title: `Fetching the model ${guid}` };
    const url = version ? `get/file/${guid}?version=${version}` : `get/file/${guid}`;

    return this.get<{ data: ModelFile }>(url, monitor).pipe(map(({ data }) => data));
  }

  public fetchObjectRoles(): Observable<Option[]> {
    return this.get<{
      data: LookupOption[];
    }>(`get/lookup-options`).pipe(
      map(({ data }) =>
        data.map(x => ({
          id: x.id,
          label: `${x.LookupCategory.name}:${x.name}`,
        })),
      ),
    );
  }

  public fetchUserInfo(): Observable<UserInfo> {
    return this.get<{ data: UserInfo }>(`user/me`).pipe(map(({ data }) => data));
  }

  public saveModelData(
    id: string,
    model: unknown,
  ): Observable<{ data: ModelFile; status: number; message: string }> {
    return this.update(`update/file-content/${id}`, { application: 'wb', model });
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TaskMonitorService } from '../services/task-monitor.service';

export const monitorRequestHeaderName = (): string => 'wb-monitor-request';

@Injectable()
export class MonitorRequestInterceptor implements HttpInterceptor {
  constructor(private readonly processMonitorService: TaskMonitorService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const monitorRequestEnabled = request.headers.has(monitorRequestHeaderName());
    const correlationId = `${request.url}-${new Date().getTime()}`;

    if (monitorRequestEnabled) {
      this.processMonitorService.addTask(
        correlationId,
        request.headers.get(monitorRequestHeaderName()),
      );
      // Remove the custom header as we don't need it anymore
      request = request.clone({ headers: request.headers.delete(monitorRequestHeaderName()) });
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (monitorRequestEnabled) {
          this.processMonitorService.completeTask(correlationId);
        }
      }),
    );
  }
}

import { MfmModel } from '@workbench/business-logic/models/mfm.model';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { ExposureState } from '@workbench/common/types/exposure-state-value.type';
import { ProcessVariableValue } from '@workbench/common/types/process-variable-value.type';
import { processVariableConverter } from '@workbench/common/utils/backward-compatibility-supporter-util';
import { exist, it, or } from '@workbench/common/utils/logical-utility';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxConstants, mxGeometry, mxgraph, mxPoint, mxUtils } from '@workbench/dts/mxg';
import {
  getConceptAttributes,
  getDefaultConcept,
} from '@workbench/multilevel-flow-modeling/core/mfm-attributes';
import { getConceptLabel } from '@workbench/multilevel-flow-modeling/core/mfm-concept-label';
import { isBAR, isSTO, isSUB, isTRA } from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { CellBuilder } from '@workbench/mx-graph/cell-builder';
import { LinkPortName } from '@workbench/mx-graph/enums/link-port-name.enum';
import { MfmConceptConfiguration } from './mfm-model.model';

export const createGraphCells = (
  data: unknown,
  model: Map<string, MfmConceptConfiguration>,
): mxgraph.mxCell[] => {
  const builder = new CellBuilder();

  return Array.from(data as Iterable<{ id: string; geometry: unknown }>).map(({ id, geometry }) => {
    const mfmConcept = model.get(id);
    const label = getConceptLabel(mfmConcept);
    const cell = builder.create(mfmConcept.concept);

    cell.setId(id);
    cell.setValue(label);

    Object.assign(cell.mfm, {
      id: mfmConcept.id,
      label: getNotEmpty(mfmConcept.label),
      linkSourceId: getNotEmpty(mfmConcept.linkSourceId),
      linkSourcePortName: getNotEmpty(mfmConcept.linkSourcePortName),
      linkTargetId: getNotEmpty(mfmConcept.linkTargetId),
      linkTargetPortName: getNotEmpty(mfmConcept.linkTargetPortName),
      mainFunction: null,
      parentId: getNotEmpty(mfmConcept.parentId),
    } as MfmModel);

    if ((geometry ?? null) !== null) {
      // prettier-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { x, y, width: w, height: h, rotation, points, source: s, target: t } = geometry as any;

      if (rotation) {
        cell.setStyle(mxUtils.setStyle(cell.getStyle(), mxConstants.STYLE_ROTATION, rotation));
      }
      if (cell.isVertex()) {
        cell.setGeometry(new mxGeometry(x, y, w, h));
      }
      if (cell.isEdge()) {
        const g: mxgraph.mxGeometry = new mxGeometry();

        g.points = points?.map(({ x: px, y: py }) => new mxPoint(px, py));
        g.sourcePoint = new mxPoint(s.x, s.y);
        g.targetPoint = new mxPoint(t.x, t.y);
        cell.setGeometry(g);
      }
    }

    return cell;
  });
};

export function expandSubModels(concepts: MfmConceptConfiguration[]): MfmConceptConfiguration[] {
  return concepts.concat(
    concepts
      .filter(({ concept, subModelTerminals }) =>
        it(
          isSUB(() => concept),
          exist(() => subModelTerminals),
        ),
      )
      .flatMap(({ id: subModelId, subModelTerminals: { inputs, outputs, sinks, sources } }) => [
        ...inputs.map(({ concept, id, label }) =>
          Object.assign(getDefaultConcept(), { concept, id: `${subModelId}.${id}`, label }),
        ),
        ...outputs.map(({ concept, id, label }) =>
          Object.assign(getDefaultConcept(), { concept, id: `${subModelId}.${id}`, label }),
        ),
        ...sinks.map(({ concept, id, label }) =>
          Object.assign(getDefaultConcept(), { concept, id: `${subModelId}.${id}`, label }),
        ),
        ...sources.map(({ concept, id, label }) =>
          Object.assign(getDefaultConcept(), { concept, id: `${subModelId}.${id}`, label }),
        ),
      ]),
  );
}

/**
 * Creates an MFM concept based on the provided data from the JSON file.
 *
 * @param item an object that contains data related to the concept
 * @returns An MFM concept with all necessary attributes and values
 */
export function conceptMapper(item: {
  concept: ResourceConcept;
  processVariable: ProcessVariableValue;
}): MfmConceptConfiguration {
  const attrs = getConceptAttributes(item.concept, {
    processVariable: item.processVariable,
  });

  return attrs.reduce(
    (concept, prop) =>
      Object.assign(
        concept,
        propertyMapper.get(prop)?.(item) ?? { [prop]: getNotEmpty(item[prop]) },
      ),
    getDefaultConcept(),
  );
}

const propertyMapper = new Map<
  keyof MfmConceptConfiguration,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (obj: any) => Partial<MfmConceptConfiguration>
>(
  /* eslint-disable @typescript-eslint/explicit-function-return-type */
  [
    [
      'actuatorTag',
      arg => ({
        actuatorTag: arg.actuatorTag ?? '',
      }),
    ],
    [
      'actuatorType',
      arg => ({
        // The `actuatorType` property, that comes from the flat-file,
        // can have the next values: 'automatic', 'manual', or 'provisional'.
        // Currently we only support the 'provisional' value.
        actuatorType: arg.actuatorType === 'provisional' ? 'provisional' : '',
      }),
    ],
    [
      'equipment',
      arg => ({
        equipment: arg.groups?.equipment ?? '',
      }),
    ],
    [
      'exposed',
      arg => ({
        exposed: exposureAttributeConverter(arg.concept, arg.exposed),
      }),
    ],
    [
      'extras',
      arg => ({
        extras: arg.extras,
      }),
    ],
    [
      'instrumentTag',
      arg => ({
        instrumentTag: arg.groups?.instrumentTag ?? '',
      }),
    ],
    [
      'levelComponentLabel',
      arg => ({
        levelComponentLabel: arg.groups?.componentLabel ?? '',
      }),
    ],
    [
      'levelComponentTag',
      arg => ({
        levelComponentTag: arg.groups?.componentTag ?? '',
      }),
    ],
    [
      'levelPartLabel',
      arg => ({
        levelPartLabel: arg.groups?.partLabel ?? '',
      }),
    ],
    [
      'levelPartTag',
      arg => ({
        levelPartTag: arg.groups?.partTag ?? '',
      }),
    ],
    [
      'levelSubUnitLabel',
      arg => ({
        levelSubUnitLabel: arg.groups?.subUnitLabel ?? '',
      }),
    ],
    [
      'levelSubUnitTag',
      arg => ({
        levelSubUnitTag: arg.groups?.subUnitTag ?? '',
      }),
    ],
    [
      'linkSourceId',
      arg => ({
        linkSourceId: arg.start ?? '',
      }),
    ],
    [
      'linkSourcePortName',
      arg => ({
        linkSourcePortName:
          arg.endPort === 'upstream'
            ? LinkPortName.Out
            : arg.endPort === 'downstream'
            ? LinkPortName.In
            : '',
      }),
    ],
    [
      'linkTargetPortName',
      arg => ({
        linkTargetPortName:
          arg.endPort === 'upstream'
            ? LinkPortName.In
            : arg.endPort === 'downstream'
            ? LinkPortName.Out
            : '',
      }),
    ],
    [
      'linkTargetId',
      arg => ({
        linkTargetId: arg.end ?? '',
      }),
    ],
    [
      'logicGateVotes',
      arg => ({
        logicGateVotes: isNaN(parseInt(arg.n, 10)) === false ? parseInt(arg.n, 10) : null,
      }),
    ],
    [
      'mainFunction',
      arg => ({
        mainFunction: null,
      }),
    ],
    [
      'parentId',
      arg => ({
        parentId: arg.parent ?? '',
      }),
    ],
    [
      'pipingInstrumentationDiagram',
      arg => ({
        pipingInstrumentationDiagram: arg.groups?.pipingInstrumentationDiagram ?? '',
      }),
    ],
    [
      'processVariable',
      arg => ({
        processVariable: processVariableConverter(arg.processVariable),
      }),
    ],
    [
      'subModelGuid',
      arg => ({
        subModelGuid: arg.model?.guid ?? '',
      }),
    ],
    [
      'subModelCanBeUpdated',
      arg => ({
        subModelCanBeUpdated: arg.model?.uncontrolled ?? true,
      }),
    ],
    [
      'subModelName',
      arg => ({
        subModelName: arg.model?.name ?? '',
      }),
    ],
    [
      'subModelTagging',
      arg => ({
        subModelTagging: arg.model?.tagging
          ? {
              actuator: arg.model?.tagging?.actuator.map(({ badge, global, id, local, state }) => ({
                global,
                id,
                state,
              })),
              controller: arg.model?.tagging?.controller.map(
                ({ badge, global, id, local, state }) => ({
                  global,
                  id,
                  state,
                }),
              ),
              equipment: arg.model?.tagging?.equipment.map(
                ({ badge, global, submodelId, local, state }) => ({
                  global,
                  state,
                  submodelId,
                }),
              ),
              processVariable: arg.model?.tagging?.processVariable.map(
                ({ expression, global, id, state, type }) => ({
                  expression,
                  global,
                  id,
                  state,
                  type,
                }),
              ),
            }
          : null,
      }),
    ],
    [
      'subModelTerminals',
      arg => ({
        subModelTerminals: arg.model
          ? {
              inputs: arg.model?.input ?? [],
              outputs: arg.model?.output ?? [],
              sinks: arg.model?.sinks ?? [],
              sources: arg.model?.sources ?? [],
            }
          : null,
      }),
    ],
    [
      'subModelVersion',
      arg => ({
        subModelVersion: arg.model?.timestamp ?? '',
      }),
    ],
    [
      'systemLabel',
      arg => ({
        systemLabel: arg.groups?.systemLabel ?? '',
      }),
    ],
    [
      'systemTag',
      arg => ({
        systemTag: arg.groups?.systemTag ?? '',
      }),
    ],
    [
      'tag',
      arg => ({
        tag: arg.groups?.tag ?? '',
      }),
    ],
  ],
  /* eslint-enable @typescript-eslint/explicit-function-return-type */
);

/**
 * Converts the value of an exposure attribute based on the given concept.
 * This is backward compatibility support for the old concepts.
 * If BAR, STO, or TRA concepts are exposed, they are considered as input.
 *
 * @param concept - The resource concept.
 * @param value - The value of the exposure attribute.
 * @returns The converted `exposed` value.
 */
function exposureAttributeConverter(concept: ResourceConcept, value: unknown): ExposureState {
  if (
    it(
      () => value === true,
      or(
        isBAR(() => concept),
        isSTO(() => concept),
        isTRA(() => concept),
      ),
    )
  ) {
    return 'input';
  }

  return (value as ExposureState) ?? false;
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CausalAnalysisApiService } from '@workbench/common/services/api/causal-analysis-api.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  causalAnalysisFault,
  causalAnalysisSuccess,
  runCausalAnalysis,
  setCausalAnalysisResults,
} from './causal-analysis.actions';

@Injectable()
export class CausalAnalysisEffects {
  public readonly performCausalAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runCausalAnalysis),
      switchMap(action =>
        this.causalAnalysisApiService.runCausalAnalysis(action.model).pipe(
          map(payload => causalAnalysisSuccess({ payload })),
          catchError((error: HttpErrorResponse) =>
            of(causalAnalysisFault({ payload: error.message })),
          ),
        ),
      ),
    ),
  );

  public readonly performCausalAnalysisSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(causalAnalysisSuccess),
      map(({ payload }) => setCausalAnalysisResults({ payload })),
    ),
  );

  public readonly performCausalAnalysisFault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(causalAnalysisFault),
        tap(({ payload }) => this.notificationService.error(payload)),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly causalAnalysisApiService: CausalAnalysisApiService,
  ) {}
}

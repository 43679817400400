import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ModelApiService } from '@workbench/common/services/api/model-api.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { updateConceptProperties } from '../model-builder/model-builder.actions';
import * as reasoningAction from '../reasoning/reasoning.actions';
import { modelApiActions } from './model-api.actions';

@Injectable()
export class ModelApiEffects {
  public readonly fetchGenericTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchGenericTags),
      switchMap(({ guid, version }) =>
        this.modelApiService.loadGenericTags(guid, version).pipe(
          map(response =>
            modelApiActions.fetchGenericTagsSucceeded({ guid, version, data: response.tagging }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              modelApiActions.fetchGenericTagsFault({
                message: error.error?.detail ?? error.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly fetchLibraryModelDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchLibraryModelDetails),
      mergeMap(action =>
        this.modelApiService.loadLibraryModelDetails(action.libraryModelId, action.version).pipe(
          map(response =>
            modelApiActions.fetchLibraryModelDetailsSucceeded({
              conceptId: action.conceptId,
              latest: action.version === 'latest',
              subModel: response,
              tag: action.tag,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              modelApiActions.fetchLibraryModelDetailsFault({
                conceptId: action.conceptId,
                message: error.error?.detail ?? error.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly fetchLibraryModelDetailsFault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchLibraryModelDetailsFault),
      tap(({ message }) => this.notificationService.error(message)),
      // We need to reset the `subModelGuid` attribute of the concept
      // to the last valid value in the PropertyEditor if the requested model has not been fetched.
      // This trick forces the PropertyEditor to re-set the value.
      map(({ conceptId }) => updateConceptProperties({ ids: [conceptId], changes: {} })),
    ),
  );

  public readonly resolveModelAndExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelAndExport),
      switchMap(({ model }) =>
        this.modelApiService.resolveModel(model).pipe(
          map(response => modelApiActions.resolveModelAndExportSucceeded({ model: response })),
          catchError((error: HttpErrorResponse) =>
            of(
              modelApiActions.resolveModelFault({ message: error.error?.detail ?? error.message }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly resolveModelAndReason$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelAndRunReasoning),
      switchMap(({ model }) =>
        this.modelApiService.resolveModelReasoningFormat(model).pipe(
          map(extendedModel => reasoningAction.runReasoning({ extendedModel })),
          catchError((error: HttpErrorResponse) =>
            of(
              modelApiActions.resolveModelFault({ message: error.error?.detail ?? error.message }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly resolveModelAndValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelAndValidate),
      switchMap(({ model }) =>
        this.modelApiService.resolveModelValidationFormat(model).pipe(
          map(extendedModel => reasoningAction.validate({ extendedModel })),
          catchError((error: HttpErrorResponse) =>
            of(
              modelApiActions.resolveModelFault({ message: error.error?.detail ?? error.message }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly resolveModelAndValidateBeforeReasoning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelAndValidateBeforeReasoning),
      switchMap(({ model }) =>
        this.modelApiService.resolveModelValidationFormat(model).pipe(
          map(extendedModel => reasoningAction.validateBeforeReasoning({ extendedModel })),
          catchError((error: HttpErrorResponse) =>
            of(
              modelApiActions.resolveModelFault({ message: error.error?.detail ?? error.message }),
            ),
          ),
        ),
      ),
    ),
  );

  // This effect is used to fetch the information about the nested models.
  // In the first step, we try to fetch the information about the models
  // without the version. If the shallow search does not return any issues,
  // we try to follow up with the deep search to get the whole information.
  public readonly resolveModelTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelTree),
      switchMap(({ model }) =>
        this.modelApiService.loadModelTree(model, false).pipe(
          switchMap(response =>
            response.no_version.length === 0
              ? this.modelApiService.loadModelTree(model, true)
              : of(response),
          ),
          map(response => modelApiActions.resolveModelTreeSucceeded({ response })),
          catchError((error: HttpErrorResponse) =>
            of(
              modelApiActions.resolveModelTreeFault({
                message: error.error?.detail ?? error.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly resolveModelFault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          modelApiActions.fetchGenericTagsFault,
          modelApiActions.resolveModelFault,
          modelApiActions.resolveModelTreeFault,
        ),
        tap(({ message }) => this.notificationService.error(message)),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly modelApiService: ModelApiService,
    private readonly notificationService: NotificationService,
  ) {}
}

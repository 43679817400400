import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LibraryTagging } from '@workbench/common/models/library-tagging.model';
import { exist, it } from '@workbench/common/utils/logical-utility';
import { TaskMonitorService } from '@workbench/core/services/task-monitor.service';
import { isSUB } from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { map, tap } from 'rxjs';
import * as libraryAction from '../library/library.actions';
import { selectImportedModel } from '../model-builder/selectors/model-builder.selectors';
import { updateGraphCellStyles } from './graph.actions';
import { conceptMapper, expandSubModels } from './import-model';
import { importActions } from './import-model.actions';
import * as modelBuilderAction from './model-builder.actions';

/**
 *  Start > Cra Labels > Set Causal Analysis Desc.
 *        > Create Model > Create Model Completed > Create Graph > Create Graph Completed > Completed
 *                                                > Set Mfm Model
 */

@Injectable()
export class ImportEffects {
  public readonly addTask$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(importActions.start),
        tap(() => this.taskMonitorService.addTask('import-model', 'Performing model import')),
      ),
    { dispatch: false },
  );

  public readonly importCraLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.start),
      concatLatestFrom(() => this.store.select(selectImportedModel)),
      map(([, data]) => importActions.importCraLabels({ data })),
    ),
  );

  public readonly importSubModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.start),
      concatLatestFrom(() => this.store.select(selectImportedModel)),
      map(([, data]) => importActions.importSubModels({ data })),
    ),
  );

  public readonly importModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.createGraphCompleted),
      map(() => importActions.completed()),
    ),
  );

  public readonly processCraLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.importCraLabels),
      map(({ data }) =>
        Array.from(data as [])
          .filter((item: { labels: unknown }) => it(exist(() => item.labels)))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((item: any) =>
            Object.assign(
              { functionId: item.id },
              item.labels.ec
                ? {
                    endConsequence: {
                      description: item.labels.ec.description,
                      iso: item.labels.ec.iso,
                      label: item.labels.ec.label,
                      mitigation: item.labels.ec.mitigation,
                      preventiveActions: item.labels.ec.preventive,
                    },
                  }
                : {},
              item.labels.rc
                ? {
                    rootCause: {
                      correctiveActions: item.labels.rc.corrective[0],
                      description: item.labels.rc.description,
                      iso: item.labels.rc.iso,
                      inspectionPoints: item.labels.rc.inspection,
                      label: item.labels.rc.label,
                      mitigation: item.labels.rc.mitigation,
                    },
                  }
                : {},
              item.labels.inspection ? { inspectionPoint: item.labels.inspection } : {},
              item.labels.preventive ? { preventiveAction: item.labels.preventive } : {},
            ),
          ),
      ),
      map(data => modelBuilderAction.setCausalAnalysisDescription({ data })),
    ),
  );

  public readonly processSubModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.importSubModels),
      map(({ data }) =>
        Array.from(data as [])
          .filter((item: { concept; model }) =>
            it(
              isSUB(() => item.concept),
              exist(() => item.model),
            ),
          )
          .map((item: { model }) => ({
            guid: item.model.guid.split('@')[0],
            name: item.model.name,
            version: item.model.guid.split('@')[1] ?? '',
            input: item.model.input,
            output: item.model.output,
            sinks: item.model.sinks,
            sources: item.model.sources,
            tagging: item.model.tagging
              ? ({
                  actuator: item.model.tagging.actuator.map(
                    ({ badge, global, id, local, state }) => ({
                      badge,
                      global,
                      id,
                      local,
                      state,
                    }),
                  ),
                  controller: item.model.tagging.controller.map(
                    ({ badge, global, id, local, state }) => ({
                      badge,
                      global,
                      id,
                      local,
                      state,
                    }),
                  ),
                  equipment: item.model.tagging.equipment.map(
                    ({ badge, global, submodelId, local, state }) => ({
                      badge,
                      global,
                      submodelId,
                      local,
                      state,
                    }),
                  ),
                  processVariable: item.model.tagging.processVariable.map(
                    ({ badge, expression, global, id, local, type }) => ({
                      badge,
                      expression: '',
                      global: '',
                      id,
                      local,
                      type: '',
                    }),
                  ),
                } as LibraryTagging)
              : null,
          })),
      ),
      map(data => libraryAction.setLibraryModels({ models: data })),
    ),
  );

  public readonly buildGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.start),
      concatLatestFrom(() => this.store.select(selectImportedModel)),
      map(([, data]) => importActions.createModel({ data })),
    ),
  );

  // Based on the 'MFM flat file' generating a list of MFM concepts.
  // Each concept consists of a set of attributes with default values
  // augmented by attributes (that are specific to this particular MFM type)
  // with values from the file
  public readonly createModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.createModel),
      map(({ data }) => Array.from(data as []).map(item => conceptMapper(item))),
      map(concepts => expandSubModels(concepts)),
      map(model => importActions.createModelCompleted({ model })),
    ),
  );

  public readonly updateCells$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.completed),
      map(() => updateGraphCellStyles()),
    ),
  );

  public readonly completeTask$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(importActions.completed),
        tap(() => this.taskMonitorService.completeTask('import-model')),
      ),
    { dispatch: false },
  );

  public readonly completeImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.createModelCompleted),
      map(() => importActions.createGraph()),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly taskMonitorService: TaskMonitorService,
  ) {}
}

import { ProcessVariableValue } from '../types/process-variable-value.type';

/**
 * Function converts provided value to the suitable Process Variable value.
 * It called to provide backward compatibility with unsupported values.
 * E.g. `undefined` or `true`
 *
 * @param value any provided value
 */
export const processVariableConverter = (value: unknown): ProcessVariableValue => {
  // `undefined` value is equal to `false`
  value ??= false;
  // If the provided value is `true`, it converts to 'online'
  if (value === true) {
    // 'online' is a fallback value
    return 'online';
  }

  return value as ProcessVariableValue;
};

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, AuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { AppConfigService } from 'app-config.service';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class PortalAuthInterceptor implements HttpInterceptor {
  constructor(private readonly auth: MsalService, private readonly config: AppConfigService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.startsWith(this.config.getConfig().portalApiBaseUrl) ||
      request.url.startsWith(this.config.getConfig().modelApiBaseUrl)
    ) {
      return this.auth
        .acquireTokenSilent({
          scopes: [portalApiScope()],
        })
        .pipe(
          catchError(
            (
              err: InteractionRequiredAuthError | AuthError,
              caught: Observable<AuthenticationResult>,
            ) => {
              if (err instanceof InteractionRequiredAuthError) {
                return this.auth.acquireTokenPopup({
                  scopes: [portalApiScope()],
                });
              }
              if (err instanceof AuthError) {
                return this.auth.acquireTokenPopup({
                  scopes: [portalApiScope()],
                });
              }

              return caught;
            },
          ),
          switchMap(authResult =>
            next.handle(
              request.clone({
                headers: request.headers.set(`Authorization`, `Bearer ${authResult.accessToken}`),
              }),
            ),
          ),
        );
    }

    return next.handle(request);
  }
}

const portalApiScope = (): string => 'https://kairoshazop.onmicrosoft.com/portal-api/portal.read';

import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { TaskMonitorService } from '@workbench/core/services/task-monitor.service';
import { TitleService } from '@workbench/core/services/title.service';
import { customIcons } from 'custom-icons';
import { filter } from 'rxjs';

@Component({
  selector: 'wb-root',
  styleUrls: ['./root.component.scss'],
  templateUrl: './root.component.html',
})
export class RootComponent {
  public readonly tasks$ = this.processMonitorService.tasks$;

  constructor(
    private readonly authService: MsalService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly sanitizer: DomSanitizer,
    private readonly titleService: TitleService,
    private readonly processMonitorService: TaskMonitorService,
  ) {
    this.titleService.title$.subscribe(title => (document.title = title));
    this.msalBroadcastService.inProgress$
      .pipe(filter(status => status === InteractionStatus.None))
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
            msg.eventType === EventType.SSO_SILENT_FAILURE,
        ),
      )
      .subscribe(() => {
        this.authService.loginRedirect();
      });
    // Register custom icons
    customIcons().forEach(([icon, path]) => this.registerIcon(icon, path));
  }

  private registerIcon(iconName: string, pathToIcon: string): void {
    this.iconRegistry.addSvgIcon(
      iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(pathToIcon),
    );
  }

  private checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    if (this.authService.instance.getActiveAccount() ?? null === null) {
      const [firstAccount] = this.authService.instance.getAllAccounts();

      if (firstAccount ?? null !== null) {
        this.authService.instance.setActiveAccount(firstAccount);
      }
    }
  }
}

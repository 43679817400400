import { getDefaultConcept } from '@workbench/multilevel-flow-modeling/core/mfm-attributes';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { ResourceConcept } from '../enums/resource-concept.enum';

export const createMockConcept = (
  id: string,
  concept: ResourceConcept,
  ext?: Partial<MfmConceptConfiguration>,
): MfmConceptConfiguration => Object.assign(getDefaultConcept(), { id, concept }, ext);

// prettier-ignore
export const logTime = (t = new Date()): string =>
  `[${pad(2, t.getHours())}:${pad(2, t.getMinutes())}:${pad(2, t.getSeconds())}.${pad(3, t.getMilliseconds())}]`;

const pad = (digits: number, v: number): string => {
  const s1 = v.toString();
  const s2 = '0'.repeat(digits).concat(s1);

  return s2.substring(s2.length - Math.max(digits, s1.length));
};

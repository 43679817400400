import { createReducer, on } from '@ngrx/store';
import { Option } from '@workbench/common/models/option';
import { produce } from 'immer';
import { setObjectRoleOptions } from './options.actions';

const initialState: OptionsState = {
  objectRole: [],
};

export interface OptionsState {
  objectRole: Option[];
}

export const optionsReducer = createReducer(
  initialState,
  on(
    setObjectRoleOptions,
    produce((state, action) => {
      state.objectRole = action.options;
    }),
  ),
);

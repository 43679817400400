import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { CausalAnalysisPropagationPath } from '@workbench/common/models/causal-reasoning-path.model';
import { causalAnalysisReducer } from '@workbench/state/causal-analysis';
import {
  modelBuilderReducer,
  ModelBuilderState,
} from '@workbench/state/model-builder/model-builder.reducer';
import { environment } from 'environments/environment';
import { accountReducer, AccountState } from './account';
import { libraryReducer, LibraryState } from './library';
import { optionsReducer, OptionsState } from './options';
import { reasoningReducer, ReasoningState } from './reasoning';

export interface AppState {
  account: AccountState;
  causalAnalysis: CausalAnalysisPropagationPath[];
  library: LibraryState;
  model: ModelBuilderState;
  options: OptionsState;
  reasoning: ReasoningState;
}

export const reducers: ActionReducerMap<AppState> = {
  account: accountReducer,
  causalAnalysis: causalAnalysisReducer,
  library: libraryReducer,
  model: modelBuilderReducer,
  options: optionsReducer,
  reasoning: reasoningReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LibraryComponentDetailedModel } from '@workbench/common/models/library-component.model';
import { LibraryTagging } from '@workbench/common/models/library-tagging.model';
import { ModelSyntaxError } from '@workbench/common/models/model-syntax-error.model';
import { ModelTreeResponse } from '@workbench/common/models/model-tree-response.model';
import { AppConfigService } from 'app-config.service';
import { Observable } from 'rxjs';
import { BaseApi } from './base-api';

@Injectable({
  providedIn: 'root',
})
export class ModelApiService extends BaseApi {
  constructor(appConfig: AppConfigService, httpClient: HttpClient) {
    super(appConfig.getConfig().modelApiBaseUrl, httpClient);
  }

  public loadGenericTags(guid: string, version: string): Observable<{ tagging: LibraryTagging }> {
    return this.get(`resolve/${guid}?version=${version}`, {
      on: true,
      title: 'Fetching the generic tags',
    });
  }

  public loadLibraryModelDetails(
    id: string,
    version: string,
  ): Observable<LibraryComponentDetailedModel> {
    const monitor = { on: true as const, title: `Fetching the details ${id}@${version}` };

    if (version === 'latest') {
      return this.get<LibraryComponentDetailedModel>(`get/exposed_functions/${id}`, monitor);
    }

    return this.get<LibraryComponentDetailedModel>(
      `get/exposed_functions/${id}?version=${version}`,
      monitor,
    );
  }

  public loadModelTree(model: unknown, deepSearch: boolean): Observable<ModelTreeResponse> {
    return this.post(
      `model_tree?deep_search=${deepSearch}`,
      { model },
      { on: true, title: 'Getting the information about the nested models' },
    );
  }

  public resolveModel(model: unknown): Observable<unknown> {
    return this.post(
      'resolve_to_flat_format',
      { model },
      { on: true, title: 'Resolving the model' },
    );
  }

  public resolveModelValidationFormat(model: unknown): Observable<unknown> {
    return this.post(
      'resolve_to_validation_format',
      { model },
      { on: true, title: 'Resolving the model' },
    );
  }

  public resolveModelReasoningFormat(model: unknown): Observable<unknown> {
    return this.post(
      'resolve_to_reasoning_format',
      { model },
      { on: true, title: 'Resolving the model' },
    );
  }

  public validate(data: unknown): Observable<ModelSyntaxError[]> {
    return this.post('model/validate', JSON.stringify(data), {
      on: true,
      title: 'Validating the model',
    });
  }
}

import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { monitorRequestHeaderName } from '@workbench/core/interceptors/monitor-request.interceptor';
import { Observable } from 'rxjs';

type ContentType = 'json' | 'json-patch';

/* eslint-disable @typescript-eslint/naming-convention */
const contentTypeHeader = (contentType: ContentType): { 'Content-Type': string } => {
  switch (contentType) {
    case 'json':
      return { 'Content-Type': 'application/json' };
    case 'json-patch':
      return { 'Content-Type': 'application/json-patch+json' };
    default:
      return { 'Content-Type': 'application/json' };
  }
};
/* eslint-enable @typescript-eslint/naming-convention */

export abstract class BaseApi {
  constructor(
    private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly apiEndpoint = '',
  ) {}

  protected download(
    path: string,
    reportProgress: boolean,
    responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'arraybuffer',
  ): Observable<HttpEvent<ArrayBuffer>> {
    const req = new HttpRequest('GET', `${this.baseUrl}/${path}`, {
      observe: 'events',
      reportProgress,
      responseType,
    });

    return this.http.request<ArrayBuffer>(req);
  }

  protected delete(path: string): Observable<unknown> {
    const opts = { headers: headers() };

    return this.http.delete(`${this.baseUrl}${this.apiEndpoint}/${path}`, opts);
  }

  protected get<T>(path: string, monitor?: { on: true; title: string }): Observable<T> {
    const opts = { headers: headers() };

    if (monitor?.on === true) {
      opts.headers = this.monitor(opts.headers, monitor.title);
    }

    return this.http.get<T>(`${this.baseUrl}${this.apiEndpoint}/${path}`, opts);
  }

  protected patch(path: string, patch: unknown): Observable<unknown> {
    const opts = { headers: headers('json-patch') };

    return this.http.patch(`${this.baseUrl}${this.apiEndpoint}/${path}`, patch, opts);
  }

  protected update<T, R>(path: string, body: T): Observable<R> {
    const opts = { headers: headers('json') };

    return this.http.patch<R>(`${this.baseUrl}${this.apiEndpoint}/${path}`, body, opts);
  }

  protected post<T = unknown>(
    path: string,
    body: unknown | null,
    monitor?: { on: true; title: string },
  ): Observable<T> {
    const opts = { headers: headers() };

    if (monitor?.on === true) {
      opts.headers = this.monitor(opts.headers, monitor.title);
    }

    return this.http.post<T>(`${this.baseUrl}${this.apiEndpoint}/${path}`, body, opts);
  }

  protected put<T = unknown>(
    path: string,
    body: unknown | null,
    monitor?: { on: true; title: string },
  ): Observable<T> {
    const opts = { headers: headers() };

    if (monitor?.on === true) {
      opts.headers = this.monitor(opts.headers, monitor.title);
    }

    return this.http.put<T>(`${this.baseUrl}${this.apiEndpoint}/${path}`, body, opts);
  }

  protected monitor(headers: HttpHeaders, title = ''): HttpHeaders {
    return headers.set(monitorRequestHeaderName(), title);
  }
}

const headers = (contentType: ContentType = 'json'): HttpHeaders =>
  new HttpHeaders({ ...contentTypeHeader(contentType) });

import { Injectable } from '@angular/core';
import { mxgraph } from '@workbench/dts/mxg';

@Injectable({
  providedIn: 'root',
})
export class CanvasTooltipService {
  private tooltipElement: HTMLElement;

  public showTooltip(bounds: mxgraph.mxRectangle, tip: string | string[]): void {
    if (typeof tip === 'string') {
      tip = [tip];
    }
    if ((this.tooltipElement ?? null) === null) {
      this.tooltipElement = document.querySelector('div#mxGraphTooltip');
    }

    const container = document.querySelector<HTMLElement>('.geDiagramContainer.geDiagramBackdrop');
    const x = bounds.x + bounds.width / 2 - container.scrollLeft + container.offsetLeft;
    const y = bounds.y - container.scrollTop + container.offsetTop;

    this.tooltipElement.style.visibility = 'visible';
    this.tooltipElement.style.left = `${x}px`;
    this.tooltipElement.style.top = `${y}px`;
    this.tooltipElement.innerHTML = tip.map(s => `<div>${s}</div>`).join('');
  }

  public hideTooltip(): void {
    if ((this.tooltipElement ?? null) !== null) {
      this.tooltipElement.style.visibility = 'hidden';
    }
  }
}

import { ResourceConcept } from '../enums/resource-concept.enum';

export const getTerminalIds = (
  id: string,
  subModel: Pick<LibraryComponentDetailedModel, 'input' | 'output' | 'sinks' | 'sources'>,
): Set<string> =>
  new Set([
    ...subModel.input.map(x => `${id}.${x.id}`),
    ...subModel.output.map(x => `${id}.${x.id}`),
    ...subModel.sinks.map(x => `${id}.${x.id}`),
    ...subModel.sources.map(x => `${id}.${x.id}`),
  ]);

export interface LibraryComponentModel {
  asset: string;
  comment: string;
  folder: string;
  guid: string;
  name: string;
  tag: string;
  version: string;
}

export type LibraryComponentDetailedModel = LibraryComponentModel & {
  input: LibraryComponentPortModel[];
  output: LibraryComponentPortModel[];
  sinks: LibraryComponentPortModel[];
  sources: LibraryComponentPortModel[];
};

interface LibraryComponentPortModel {
  badge: string[];
  concept: ResourceConcept;
  id: string;
  label: string;
}

import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { isProcessVariable } from '@workbench/common/types/process-variable-value.type';
import { it, not, or } from '@workbench/common/utils/logical-utility';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { hasAttribute } from './mfm-attributes';
import {
  isCFS,
  isEFS,
  isFunction,
  isLogicFunction,
  isMEQ,
  isMFS,
  isObjective,
  isSUB,
  isSYS,
} from './mfm-core';

export function getConceptLabel(mfm: MfmConceptConfiguration): string {
  const concept = (): ResourceConcept => mfm.concept;
  const labelNotEmpty = mfm.label.length > 0;

  // - label
  let result = mfm.label;

  if (it(isSYS(concept))) {
    // - label
    // - label: #systemTag
    if (labelNotEmpty) {
      result = `${mfm.label}`;
      if (mfm.systemTag.length > 0) {
        result = `${mfm.label}: #${mfm.systemTag}`;
      }
    }
  }
  if (it(or(isCFS(concept), isEFS(concept), isMEQ(concept), isMFS(concept)))) {
    // - label
    // - equipment
    // - equipment
    //   label
    // - #tag
    // - #tag
    //   label
    // - equipment: #tag
    // - equipment: #tag
    //   label
    if (labelNotEmpty) {
      result = mfm.label;
    }
    if (mfm.equipment.length > 0) {
      result = `${mfm.equipment}`;
      if (labelNotEmpty) {
        result = `${mfm.equipment}\n${mfm.label}`;
      }
    }
    if (mfm.tag.length > 0) {
      result = `#${mfm.tag}`;
      if (labelNotEmpty) {
        result = `#${mfm.tag}\n${mfm.label}`;
      }
    }
    if (mfm.tag.length > 0 && mfm.equipment.length > 0) {
      result = `${mfm.equipment}: #${mfm.tag}`;
      if (labelNotEmpty) {
        result = `${mfm.equipment}: #${mfm.tag}\n${mfm.label}`;
      }
    }
  }
  if (it(isFunction(concept))) {
    // - label
    // - #instrumentTag
    // - #instrumentTag
    //   label
    // - #actuatorTag
    // - #actuatorTag
    //   label
    if (labelNotEmpty) {
      result = `${mfm.label}`;
    }
    if (
      it(
        hasAttribute(mfm.concept, 'instrumentTag', {
          processVariable: mfm.processVariable,
        }),
      )
    ) {
      if (mfm.instrumentTag.length > 0) {
        result = `#${mfm.instrumentTag}`;
        if (labelNotEmpty) {
          result = `#${mfm.instrumentTag}\n${mfm.label}`;
        }
      }
    }

    // In current implementation only Actuators have actuatorTag
    // So if entity has no processVariable assigned and has actuatorTag
    // Show it instead of instrumentTag
    if (it(not(isProcessVariable(mfm.processVariable)))) {
      if (mfm.actuatorTag.length > 0) {
        result = `#${mfm.actuatorTag}`;
        if (labelNotEmpty) {
          result = `#${mfm.actuatorTag}\n${mfm.label}`;
        }
      }
    }
  }
  if (it(or(isObjective(concept), isLogicFunction(concept)))) {
    // - label
    // - #tag
    // - #tag
    //   label
    if (labelNotEmpty) {
      result = `${mfm.label}`;
    }
    if (mfm.tag.length > 0) {
      result = `#${mfm.tag}`;
      if (labelNotEmpty) {
        result = `#${mfm.tag}\n${mfm.label}`;
      }
    }
  }

  if (it(isSUB(concept))) {
    // - System: {systemLabel}: #{systemTag}
    //   P&ID: #{pipingInstrumentationDiagram}
    //   Equipment: {equipment}:#{tag}
    //   Sub-unit: {levelSubUnitLabel}: #{levelSubUnitTag}
    //   Component: {levelComponentLabel}: #{levelComponentTag}
    //   Part: {levelPartLabel}: #{levelPartTag}
    //   {label}
    const lines: string[] = [];

    if (mfm.systemLabel.length > 0 || mfm.systemTag.length > 0) {
      if (mfm.systemLabel.length > 0 && mfm.systemTag.length > 0) {
        lines.push(`System: ${mfm.systemLabel} #${mfm.systemTag}`);
      } else if (mfm.systemLabel.length > 0) {
        lines.push(`System: ${mfm.systemLabel}`);
      } else {
        lines.push(`System: #${mfm.systemTag}`);
      }
    }
    if (mfm.pipingInstrumentationDiagram.length > 0) {
      lines.push(`P&ID: ${mfm.pipingInstrumentationDiagram}`);
    }
    if (mfm.equipment.length > 0 || mfm.tag.length > 0) {
      if (mfm.equipment.length > 0 && mfm.tag.length > 0) {
        lines.push(`Equipment: ${mfm.equipment} #${mfm.tag}`);
      } else if (mfm.equipment.length > 0) {
        lines.push(`Equipment: ${mfm.equipment}`);
      } else {
        lines.push(`Equipment: #${mfm.tag}`);
      }
    }
    if (mfm.levelSubUnitLabel.length > 0 || mfm.levelSubUnitTag.length > 0) {
      if (mfm.levelSubUnitLabel.length > 0 && mfm.levelSubUnitTag.length > 0) {
        lines.push(`Sub-unit: ${mfm.levelSubUnitLabel} #${mfm.levelSubUnitTag}`);
      } else if (mfm.levelSubUnitLabel.length > 0) {
        lines.push(`Sub-unit: ${mfm.levelSubUnitLabel}`);
      } else {
        lines.push(`Sub-unit: #${mfm.levelSubUnitTag}`);
      }
    }
    if (mfm.levelComponentLabel.length > 0 || mfm.levelComponentTag.length > 0) {
      if (mfm.levelComponentLabel.length > 0 && mfm.levelComponentTag.length > 0) {
        lines.push(`Component: ${mfm.levelComponentLabel} #${mfm.levelComponentTag}`);
      } else if (mfm.levelComponentLabel.length > 0) {
        lines.push(`Component: ${mfm.levelComponentLabel}`);
      } else {
        lines.push(`Component: #${mfm.levelComponentTag}`);
      }
    }
    if (mfm.levelPartLabel.length > 0 || mfm.levelPartTag.length > 0) {
      if (mfm.levelPartLabel.length > 0 && mfm.levelPartTag.length > 0) {
        lines.push(`Part: ${mfm.levelPartLabel} #${mfm.levelPartTag}`);
      } else if (mfm.levelPartLabel.length > 0) {
        lines.push(`Part: ${mfm.levelPartLabel}`);
      } else {
        lines.push(`Part: #${mfm.levelPartTag}`);
      }
    }
    if (labelNotEmpty) {
      lines.push(mfm.label);
    }

    result = lines.join('\n');
  }

  return result;
}

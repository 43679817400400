import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { syntaxErrorsFormatter } from '@workbench/business-logic/services/reasoning/reasoning-service';
import { CausalAnalysisApiService } from '@workbench/common/services/api/causal-analysis-api.service';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { ClipboardService } from '@workbench/core/services/clipboard.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import * as fromModelBuilderActions from '../model-builder/model-builder.actions';
import * as fromModelBuilderSelectors from '../model-builder/selectors/model-builder.selectors';
import {
  copyToClipboard,
  fetchValidationResultFault,
  fetchValidationResultSuccess,
  resetReasoning,
  runReasoning,
  runReasoningFault,
  setFaultCase,
  setReasoningDisplaySection,
  setReasoningResults,
  setSelectedPathIndex,
  validate,
  validateBeforeReasoning,
} from './reasoning.actions';
import { selectCauses, selectConsequences, selectFaultCase } from './reasoning.selectors';

import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { CausalAnalysisPropagationPath } from '@workbench/common/models/causal-reasoning-path.model';
import { ModelApiService } from '@workbench/common/services/api/model-api.service';
import { toMap } from '@workbench/common/utils/array-util';
import { SyntaxIssueLevel } from '@workbench/multilevel-flow-modeling/syntax-issues-section/syntax-issue-level.enum';
import { selectReasoningVisualizationConfig } from '../meta.selectors';
import * as graphAction from '../model-builder/graph.actions';

@Injectable()
export class ReasoningEffects {
  public readonly faultCaseChangeHandler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFaultCase),
      map(() => graphAction.runReasoning()),
    ),
  );

  public readonly copyToClipboard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(copyToClipboard),
        concatLatestFrom(() => [
          this.store.select(selectCauses),
          this.store.select(selectConsequences),
          this.store.select(fromModelBuilderSelectors.selectMfmModelEntities),
        ]),
        switchMap(([action, causes, consequences, model]) => {
          // prettier-ignore
          const text = [
            ...causes.map(({ rootCause }, index) =>
                `Cause ${index + 1}\t${getNotEmpty(model[rootCause.functionId]?.label, rootCause.functionId)}\t${rootCause.state}`),
            ...consequences.map(({ endConsequence }, index) =>
                `Consequence ${index + 1}\t${getNotEmpty(model[endConsequence.functionId]?.label, endConsequence.functionId)}\t${endConsequence.state}`),
          ].join('\n');

          return this.clipboardService.write(text);
        }),
        tap(result =>
          result
            ? this.notificationService.info(`Items are copied to the clipboard`)
            : this.notificationService.info(`Items are not been copied. Please try again`),
        ),
        catchError(
          () => (this.notificationService.error('An error occurred during the copying'), of()),
        ),
      ),
    { dispatch: false },
  );

  public readonly fetchModelValidationResultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchValidationResultSuccess),
      map(({ result }) => fromModelBuilderActions.setModelValidationResult({ result })),
    ),
  );

  public readonly reasoningHighlightPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        setFaultCase,
        setSelectedPathIndex,
        setReasoningDisplaySection,
        setReasoningResults,
        fromModelBuilderActions.setPointedConcept,
      ),
      concatLatestFrom(() => this.store.select(selectReasoningVisualizationConfig)),
      // TODO: It is breaking the Labels Editor highlighting.
      // `Fault` indicates that we are in Reasoning mode.
      // In the future, we should rely on the `mode` field ('editor', 'labels', 'reasoning')
      filter(([action, { include }]) => (include.fault ?? null) !== null),
      map(([action, payload]) => graphAction.highlightCausalPaths(payload)),
    ),
  );

  public readonly reasoningClearHighlightPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetReasoning),
      map(() => graphAction.clearHighlights()),
    ),
  );

  public readonly modelHasNoValidationErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fetchValidationResultSuccess),
        filter(({ result }) => result.length === 0),
        tap(() =>
          this.notificationService.info('The model is valid. Syntax check found no errors'),
        ),
      ),
    { dispatch: false },
  );

  public readonly modelHasValidationErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fetchValidationResultSuccess),
        filter(({ result }) => result.length > 0),
        tap(() => this.notificationService.error('Model validation returned errors/warnings')),
      ),
    { dispatch: false },
  );

  public readonly runReasoningEmptyResult$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setReasoningResults),
        filter(({ causes, consequences }) => causes.length + consequences.length === 0),
        tap(() =>
          this.notificationService.info(`The current case doesn't have any causes or consequences`),
        ),
      ),
    { dispatch: false },
  );

  public readonly runReasoningFault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(runReasoningFault, fetchValidationResultFault),
        tap(({ message }) => this.notificationService.error(message)),
      ),
    { dispatch: false },
  );

  public readonly validate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validate),
      concatLatestFrom(() => this.store.select(fromModelBuilderSelectors.selectModelMap)),
      switchMap(([{ extendedModel }, model]) =>
        this.modelApiService.validate({ model: extendedModel }).pipe(
          map(result =>
            syntaxErrorsFormatter(
              result,
              model,
              toMap(extendedModel as { id: string; concept: ResourceConcept }[], 'id'),
            ),
          ),
          map(result => fetchValidationResultSuccess({ result })),
          catchError((error: HttpErrorResponse) =>
            of(
              fetchValidationResultFault({
                message: error?.message ?? 'There is an error occurred during the validation',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly validateBeforeReasoning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateBeforeReasoning),
      concatLatestFrom(() => this.store.select(fromModelBuilderSelectors.selectModelMap)),
      switchMap(([{ extendedModel }, model]) =>
        this.modelApiService.validate({ model: extendedModel }).pipe(
          map(result =>
            syntaxErrorsFormatter(
              result,
              model,
              toMap(extendedModel as { id: string; concept: ResourceConcept }[], 'id'),
            ),
          ),
          map(issues => {
            const isValid = issues.every(issue => issue.level !== SyntaxIssueLevel.Error);

            if (isValid) {
              return fromModelBuilderActions.gotoReasoningModeResolved();
            }

            return fromModelBuilderActions.gotoReasoningModeRejected({ result: issues });
          }),
          catchError((error: HttpErrorResponse) =>
            of(fetchValidationResultFault({ message: error.message })),
          ),
        ),
      ),
    ),
  );

  public readonly runReasoning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runReasoning),
      concatLatestFrom(() => this.store.select(selectFaultCase)),
      switchMap(([{ extendedModel }, fault]) =>
        this.causalAnalysisApiService.runSingleStateAnalysis(extendedModel, fault).pipe(
          map(({ causes, consequences }) => ({
            causes: causes.map(x => this.mapToCausalAnalysisPropagationPath(x)),
            consequences: consequences.map(x => this.mapToCausalAnalysisPropagationPath(x)),
          })),
          map(({ causes, consequences }) => setReasoningResults({ causes, consequences })),
          catchError(() =>
            of(runReasoningFault({ message: 'An error has occurred during the reasoning' })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly causalAnalysisApiService: CausalAnalysisApiService,
    private readonly clipboardService: ClipboardService,
    private readonly notificationService: NotificationService,
    private readonly modelApiService: ModelApiService,
    private readonly store: Store,
  ) {}

  private mapToCausalAnalysisPropagationPath(
    path: [[string, string]],
  ): CausalAnalysisPropagationPath {
    return {
      rootCause: { functionId: path[0][0], state: path[0][1] },
      endConsequence: {
        functionId: path.slice(-1)[0][0],
        state: path.slice(-1)[0][1],
      },
      nodes: path.slice(1, -1).map(([functionId, state]) => ({ functionId, state })),
    } as CausalAnalysisPropagationPath;
  }
}

import { createReducer, on } from '@ngrx/store';
import { ReasoningFaultKind } from '@workbench/business-logic/enums/reasoning-fault-kind.enum';
import { CausalAnalysisPropagationPathUnique } from '@workbench/common/models/causal-reasoning-path.model';
import { uniquify } from '@workbench/common/utils/array-util';
import produce from 'immer';
import {
  resetReasoning,
  setFaultCase,
  setReasoningDisplaySection,
  setReasoningResults,
  setSelectedPathIndex,
} from './reasoning.actions';

export const initialState = (): ReasoningState => ({
  causes: [],
  consequences: [],
  section: 'causes',
});

export interface ReasoningState {
  causes: CausalAnalysisPropagationPathUnique[];
  consequences: CausalAnalysisPropagationPathUnique[];
  section: 'causes' | 'consequences';
  fault?: { functionId: string; fault: ReasoningFaultKind };
  selectedItem?: number;
}

export const reasoningReducer = createReducer(
  initialState(),
  on(resetReasoning, () => initialState()),
  on(
    setFaultCase,
    produce((state, { functionId, fault }) => {
      if (state.fault?.functionId !== functionId || state.fault?.fault !== fault) {
        state.fault = { functionId, fault };
        state.causes = [];
        state.consequences = [];
        delete state.selectedItem;
      }
    }),
  ),
  on(
    setReasoningResults,
    produce((state, { causes, consequences }) => {
      state.causes = uniquify(causes);
      state.consequences = uniquify(consequences);
    }),
  ),
  on(
    setReasoningDisplaySection,
    produce((state, { section }) => {
      state.section = section;
      delete state.selectedItem;
    }),
  ),
  on(
    setSelectedPathIndex,
    produce((state, { index }) => {
      state.selectedItem = index;
    }),
  ),
);

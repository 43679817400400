// 2021-03-24T12:52:11.317Z
const ISO_REGEXP = /(\d+-\d+-\d+)T(\d+):(\d+):(\d+)\..*/;

/**
 * Get the current date and time in ISO format: `'2021-03-24T12:52:11.317Z'`
 *
 * Then format that for export file name: `'2021-03-24T125211'`
 *
 * - Removes the fractional part of the time
 * - Removes colons in time
 *
 * @returns String represents timestamp
 */
export function getTimestamp(): string {
  const date = new Date();

  const timestamp = date.toISOString().replace(ISO_REGEXP, '$1T$2$3$4');

  return timestamp;
}

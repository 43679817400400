import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CausalAnalysisPropagationPath } from '@workbench/common/models/causal-reasoning-path.model';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { AppConfigService } from 'app-config.service';
import { Observable } from 'rxjs';
import { BaseApi } from './base-api';

interface CausalModeReasoningResult {
  causes: [[[string, string]]];
  consequences: [[[string, string]]];
}

@Injectable({
  providedIn: 'root',
})
export class CausalAnalysisApiService extends BaseApi {
  constructor(config: AppConfigService, httpClient: HttpClient) {
    super(config.getConfig().cmrsBaseUrl, httpClient);
  }

  public runCausalAnalysis(model: MfmConcept[]): Observable<CausalAnalysisPropagationPath[]> {
    return this.post('api', { model }, { on: true, title: 'Running causal analysis' });
  }

  public runSingleStateAnalysis(
    model: unknown,
    fault: { functionId: string; fault: unknown },
  ): Observable<CausalModeReasoningResult> {
    return this.post<CausalModeReasoningResult>(
      'result',
      { model, case: [fault] },
      { on: true, title: 'Running single state analysis' },
    );
  }
}

import { createSelector } from '@ngrx/store';
import { ReasoningFaultKind } from '@workbench/business-logic/enums/reasoning-fault-kind.enum';
import {
  CausalAnalysisPropagationPath,
  CausalAnalysisPropagationPathNode,
} from '@workbench/common/models/causal-reasoning-path.model';
import * as modelBuilderSelector from './model-builder/selectors/model-builder.selectors';
import * as reasoningSelector from './reasoning/reasoning.selectors';

export const selectReasoningVisualizationConfig = createSelector(
  reasoningSelector.selectCauses,
  reasoningSelector.selectConsequences,
  reasoningSelector.selectReasoningDisplaySection,
  reasoningSelector.selectCurrentPath,
  reasoningSelector.selectFaultCase,
  modelBuilderSelector.selectPointedConcept,
  (causes, consequences, section, path, fault, pointedConcept) => {
    const result: {
      paths: CausalAnalysisPropagationPath[];
      include: {
        causes: CausalAnalysisPropagationPathNode[];
        consequences: CausalAnalysisPropagationPathNode[];
        displayState?: true;
        fault?: { functionId: string; fault: ReasoningFaultKind };
      };
    } = { paths: [], include: { causes: [], consequences: [] } };

    if (path) {
      Object.assign(result, { paths: [path] });
      if (pointedConcept) {
        const pathIds = new Set([
          path.rootCause.functionId,
          path.endConsequence.functionId,
          ...path.nodes.map(x => x.functionId),
        ]);

        if (pathIds.has(pointedConcept.id)) {
          Object.assign(result.include, { displayState: true });
        }
      }
    } else {
      Object.assign(result.include, {
        causes: section === 'causes' ? causes.map(x => x.rootCause) : [],
        consequences: section === 'consequences' ? consequences.map(x => x.endConsequence) : [],
      });
    }
    if (fault) {
      Object.assign(result.include, { fault });
    }

    return result;
  },
);

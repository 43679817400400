import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppModule } from 'app/app.module';

const routes: Routes = [
  { path: '', redirectTo: '/app/home', pathMatch: 'full' },
  {
    canActivate: [MsalGuard],
    path: 'app',
    loadChildren: (): Promise<Type<AppModule>> => import('./app/app.module').then(m => m.AppModule), // Lazy load account module
    data: { preload: true },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
  providers: [],
})
export class RootRoutingModule {}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PortalApiService } from '@workbench/common/services/api/portal-api.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  fetchObjectRoleOptions,
  fetchObjectRoleOptionsFault,
  fetchObjectRoleOptionsSuccess,
  setObjectRoleOptions,
} from './options.actions';

@Injectable()
export class OptionsEffects {
  public readonly fetchObjectRoleOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchObjectRoleOptions),
      switchMap(() =>
        this.portalApiService.fetchObjectRoles().pipe(
          map(response => fetchObjectRoleOptionsSuccess({ response })),
          catchError((error: HttpErrorResponse) =>
            of(fetchObjectRoleOptionsFault({ error: error.message })),
          ),
        ),
      ),
    ),
  );

  public readonly fetchObjectRoleOptionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchObjectRoleOptionsSuccess),
      map(action => setObjectRoleOptions({ options: action.response })),
    ),
  );

  public readonly fetchObjectRoleOptionsFault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fetchObjectRoleOptionsFault),
        tap(() =>
          this.notificationService.error(
            'An error occurred while loading the available Object Roles',
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly portalApiService: PortalApiService,
  ) {}
}

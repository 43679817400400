import { createAction, props } from '@ngrx/store';
import { CausalAnalysisPropagationPath } from '@workbench/common/models/causal-reasoning-path.model';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';

export const runCausalAnalysis = createAction(
  '[Causal Analysis/API] Run',
  props<{ model: MfmConcept[] }>(),
);

export const causalAnalysisSuccess = createAction(
  '[Causal Analysis/API] Success',
  props<{ payload: CausalAnalysisPropagationPath[] }>(),
);

export const causalAnalysisFault = createAction(
  '[Causal Analysis/API] Fault',
  props<{ payload: string }>(),
);

export const setCausalAnalysisResults = createAction(
  '[Causal Analysis] Set Results',
  props<{ payload: CausalAnalysisPropagationPath[] }>(),
);

import { MainFunction } from '@workbench/business-logic/models/main-function.model';
import { MfmModel } from '@workbench/business-logic/models/mfm.model';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { exist } from '@workbench/common/utils/logical-util';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';

// Describes the fields picked for updating from the mxCell::MfmModel
type Updatable = Pick<
  MfmConceptConfiguration,
  | 'concept'
  | 'id'
  | 'label'
  | 'linkSourceId'
  | 'linkSourcePortName'
  | 'linkTargetId'
  | 'linkTargetPortName'
  | 'mainFunction'
  | 'parentId'
>;

export const mfmConceptConfigurationMapper = (
  src: MfmModel[],
): Partial<MfmConceptConfiguration>[] => src.map(x => mfmConceptConfiguration(x));

const mfmConceptConfiguration = (src: MfmModel): Updatable => ({
  concept: src.conceptId ?? ResourceConcept.Undef,
  id: String(src.id),
  label: getNotEmpty(src.label),
  linkSourceId: getNotEmpty(src.linkSourceId),
  linkSourcePortName: getNotEmpty(src.linkSourcePortName),
  linkTargetId: getNotEmpty(src.linkTargetId),
  linkTargetPortName: getNotEmpty(src.linkTargetPortName),
  mainFunction: mainFunction(src.mainFunction),
  parentId: getNotEmpty(src.parentId),
});

const mainFunction = (src: MainFunction): MfmConceptConfiguration['mainFunction'] | null =>
  exist(src) ? { sourceId: getNotEmpty(src.sourceId), targetId: getNotEmpty(src.targetId) } : null;

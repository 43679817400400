import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PortalApiService } from '@workbench/common/services/api/portal-api.service';
import {
  ConfirmationOption,
  ConfirmationService,
} from '@workbench/core/services/confirmation.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  setImportedModel,
  setModelMetadata,
  setPermissions,
  setProject,
} from './model-builder.actions';

import { portalActions } from './portal.actions';
import { selectProjectId, selectProjectVersion } from './selectors/model-builder.selectors';

@Injectable()
export class PortalEffects {
  public readonly fetchModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModel),
      map(({ id, version }) => portalActions.fetchModelFile({ id, version })),
    ),
  );

  public readonly fetchModelFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelFile),
      switchMap(({ id, version }) =>
        this.portalApiService.fetchModelFile(id, version).pipe(
          map(file => portalActions.fetchModelFileSuccess({ file, version })),
          catchError((error: HttpErrorResponse) =>
            of(portalActions.fetchModelFileFault({ message: error.error.message })),
          ),
        ),
      ),
    ),
  );

  public readonly fetchModelFileFault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          portalActions.fetchModelDataFault,
          portalActions.fetchModelFileFault,
          portalActions.fetchModelVersionsFault,
          portalActions.saveModelDataFault,
        ),
        tap(({ message }) => this.notificationService.error(message)),
      ),
    { dispatch: false },
  );

  public readonly fetchModelFileThenData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelFileSuccess),
      map(({ file, version }) => portalActions.fetchModelData({ guid: file.guid, version })),
    ),
  );

  public readonly fetchModelFileThenUpdateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelFileSuccess),
      map(
        ({
          file: { asset, condition, extension, folder, guid, id, name, updatedAt, user, version },
        }) => {
          const modelName = name.replace(RegExp(`${extension}$`), '');
          const payload = {
            project: {
              asset: { name: asset.name },
              condition: { isEditable: condition.isEditable, state: condition.state },
              folder,
              guid,
              id,
              name: modelName,
              updatedAt,
              user,
              version,
            },
          };

          if (condition.state === 'lock') {
            Object.assign(payload.project, { lock: { id: user.id, name: user.fullName } });
          }

          return setProject(payload);
        },
      ),
    ),
  );

  public readonly fetchModelData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelData),
      switchMap(({ guid: id, version }) =>
        this.portalApiService.fetchModelData(id, version).pipe(
          map(model => portalActions.fetchModelDataSuccess({ model })),
          catchError((error: HttpErrorResponse) =>
            of(portalActions.fetchModelDataFault({ message: error.error })),
          ),
        ),
      ),
    ),
  );

  public readonly fetchModelLatestVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelVersions),
      switchMap(({ id }) =>
        this.portalApiService.fetchModelVersions(id).pipe(
          map(versions => portalActions.fetchModelVersionsSuccess({ id, versions })),
          catchError((error: HttpErrorResponse) =>
            of(portalActions.fetchModelVersionsFault({ message: error.error.message })),
          ),
        ),
      ),
    ),
  );

  public readonly setImportedModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelDataSuccess),
      map(({ model }) =>
        setImportedModel({ model: model.filter(({ concept }) => concept !== 'meta') }),
      ),
    ),
  );

  public readonly setMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelDataSuccess),
      map(({ model }) =>
        setModelMetadata({ metadata: model.find(({ concept }) => concept === 'meta') }),
      ),
    ),
  );

  // Check the latest version of the model before saving.
  // If the model has been changed since the last save,
  // ask the user to confirm, because the changes that were made on the third party
  // will be overwritten and lost.
  public readonly saveModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.saveModelData),
      concatLatestFrom(() => [
        this.store.select(selectProjectId),
        this.store.select(selectProjectVersion),
      ]),
      switchMap(([{ data }, id, version]) =>
        // Get the latest version of the model
        this.portalApiService.fetchModelVersions(`${id}`).pipe(
          map(([latest]) => latest.version === version),
          switchMap(ready =>
            // If the model's current version differs from the latest version,
            // ask the user to confirm the action
            ready
              ? of(ConfirmationOption.Confirm)
              : this.confirmationService.confirm({
                  message:
                    'The model has been changed since your last save by you or another user. Do you want to overwrite the changes?',
                  options: {
                    confirm: 'Overwrite',
                  },
                }),
          ),
          // Filter out the cancel option
          filter(confirm => confirm === ConfirmationOption.Confirm),
          // If confirmed by the user, save the model
          switchMap(() =>
            this.portalApiService.saveModelData(String(id), data).pipe(
              map(response => portalActions.saveModelDataSuccess({ modelFile: response.data })),
              catchError((error: HttpErrorResponse) =>
                of(portalActions.saveModelDataFault({ message: error.error.message })),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly saveModelDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(portalActions.saveModelDataSuccess),
        tap(() => this.notificationService.info('Model has been successfully saved')),
      ),
    { dispatch: false },
  );

  public readonly setModelFilePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelFileSuccess),
      map(({ file }) => setPermissions({ permission: { ...file.permission } })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly confirmationService: ConfirmationService,
    private readonly notificationService: NotificationService,
    private readonly portalApiService: PortalApiService,
    private readonly store: Store,
  ) {}
}

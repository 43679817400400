export const customIcons = (): [string, string][] => [
  ['wb-action-check', 'assets/icons/wb-action-check.svg'],
  ['wb-action-download', 'assets/icons/wb-action-download.svg'],
  ['wb-action-play', 'assets/icons/wb-action-play.svg'],
  ['wb-action-redo', 'assets/icons/wb-action-redo.svg'],
  ['wb-action-save', 'assets/icons/wb-action-save.svg'],
  ['wb-action-undo', 'assets/icons/wb-action-undo.svg'],
  ['wb-action-upload', 'assets/icons/wb-action-upload.svg'],
  ['wb-action-zoom-in', 'assets/icons/wb-action-zoom-in.svg'],
  ['wb-action-zoom-out', 'assets/icons/wb-action-zoom-out.svg'],
  ['wb-grid-off', 'assets/icons/wb-grid-off.svg'],
  ['wb-grid-on', 'assets/icons/wb-grid-on.svg'],
  ['wb-logo', 'assets/icons/logo.svg'],
  ['wb-mfm-bal', 'assets/icons/mfm/wb-bal.svg'],
  ['wb-mfm-bar', 'assets/icons/mfm/wb-bar.svg'],
  ['wb-mfm-dco', 'assets/icons/mfm/wb-dco.svg'],
  ['wb-mfm-haz', 'assets/icons/mfm/wb-haz.svg'],
  ['wb-mfm-mco', 'assets/icons/mfm/wb-mco.svg'],
  ['wb-mfm-pco', 'assets/icons/mfm/wb-pco.svg'],
  ['wb-mfm-sin', 'assets/icons/mfm/wb-sin.svg'],
  ['wb-mfm-sou', 'assets/icons/mfm/wb-sou.svg'],
  ['wb-mfm-sup', 'assets/icons/mfm/wb-sup.svg'],
  ['wb-mfm-sto', 'assets/icons/mfm/wb-sto.svg'],
  ['wb-mfm-tar', 'assets/icons/mfm/wb-tar.svg'],
  ['wb-mfm-tra', 'assets/icons/mfm/wb-tra.svg'],
  ['wb-mfm-undef', 'assets/icons/mfm/wb-undef.svg'],
  ['wb-reasoning-cause', 'assets/icons/wb-reasoning-cause.svg'],
  ['wb-reasoning-consequence', 'assets/icons/wb-reasoning-consequence.svg'],
  ['wb-state-breach', 'assets/icons/wb-state-breach.svg'],
  ['wb-state-control-failure-high', 'assets/icons/wb-state-control-failure-high.svg'],
  ['wb-state-control-failure-low', 'assets/icons/wb-state-control-failure-low.svg'],
  ['wb-state-false', 'assets/icons/wb-state-false.svg'],
  ['wb-state-high', 'assets/icons/wb-state-high.svg'],
  ['wb-state-low', 'assets/icons/wb-state-low.svg'],
  ['wb-state-true', 'assets/icons/wb-state-true.svg'],
  ['wb-workbench', 'assets/icons/wb-workbench.svg'],
  ['wb-zoom-actual', 'assets/icons/wb-zoom-actual.svg'],
  ['wb-zoom-all', 'assets/icons/wb-zoom-all.svg'],
  ['wb-zoom-system', 'assets/icons/wb-zoom-system.svg'],
];

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ModelApiService } from '@workbench/common/services/api/model-api.service';
import { isExposed } from '@workbench/common/types/exposure-state-value.type';
import { isProcessVariable } from '@workbench/common/types/process-variable-value.type';
import { getTimestamp } from '@workbench/common/utils/datetime-util';
import { isEmptyStr, it, not, or } from '@workbench/common/utils/logical-utility';
import { distinctUntilSetChanged } from '@workbench/common/utils/rxjs-util';
import { difference, intersection } from '@workbench/common/utils/set-util';
import {
  ConfirmationOption,
  ConfirmationService,
} from '@workbench/core/services/confirmation.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { TitleService } from '@workbench/core/services/title.service';
import { ModelBuilderMode } from '@workbench/model-project/model-builder-mode.enum';
import {
  getConceptAttributes,
  getDefaultConcept,
  getDefaults,
} from '@workbench/multilevel-flow-modeling/core/mfm-attributes';
import { isBAR, isSUB, isTRA } from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { getConceptFullName } from '@workbench/multilevel-flow-modeling/core/mfm-descriptor';
import { environment } from 'environments/environment';
import { OutputStrategy } from 'environments/output-strategy';
import { from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  tap,
} from 'rxjs/operators';
import * as libraryAction from '../library/library.actions';
import { modelApiActions } from '../model-api/model-api.actions';
import * as reasoningActions from '../reasoning/reasoning.actions';
import * as graphActions from './graph.actions';
import { importActions } from './import-model.actions';
import {
  applyStash,
  builderEditModeDeactivated,
  builderLabelsModeDeactivated,
  builderReasoningModeDeactivated,
  changeConceptProperty,
  checkObsoleteConceptsExist,
  exportJsonFile,
  gotoBuilderEditMode,
  gotoBuilderEditModeResolved,
  gotoLabelsMode,
  gotoLabelsModeResolved,
  gotoReasoningMode,
  gotoReasoningModeRejected,
  gotoReasoningModeResolved,
  importModelFromFile,
  modelBuilderAction,
  patchModel,
  setBuilderMode,
  setImportedModel,
  setInvalidSubModels,
  setMfmModel,
  setModelMetadata,
  setModelValidationResult,
  setProject,
  setVersionOpened,
  transformConcept,
  updateConceptProperties,
  updateModel,
} from './model-builder.actions';
import { portalActions } from './portal.actions';
import {
  selectMfmModel,
  selectMfmModelById,
  selectMode,
  selectModelActuators,
  selectModelFlat,
  selectModelMap,
  selectProjectName,
  selectSelectedMfmModel,
  selectSubModels,
} from './selectors/model-builder.selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let saveAs: any;

@Injectable()
export class ModelBuilderEffects {
  /**
   * This effect is triggered when a set of Actuators changed.
   * It is used to detect when a Provisional Actuator becomes a real Actuator.
   * In this case we need to reset its `actuatorType` property.
   */
  public readonly actuatorsChanged$ = createEffect(() =>
    this.store.select(selectModelActuators).pipe(
      pairwise(),
      concatLatestFrom(() => this.store.select(selectModelMap)),
      map(([[prev, curr], model]) =>
        [
          ...difference(curr.automatic, prev.automatic),
          ...difference(curr.manual, prev.manual),
        ].filter(id => model.get(id).actuatorType === 'provisional'),
      ),
      filter(ids => ids.length > 0),
      map(ids => updateConceptProperties({ ids, changes: { actuatorType: '' } })),
    ),
  );

  /**
   * Add the Sub-Models that are being used in the imported model to the library
   */
  public readonly addSubModelsThatAreUsingToTheLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.completed),
      concatLatestFrom(() => this.store.select(selectSubModels)),
      // Filter out the sub-models that don't have a corresponding library model.
      map(([action, subModels]) =>
        Array.from(subModels.values()).filter(x =>
          it(not(isEmptyStr(x.subModelGuid)), not(isEmptyStr(x.subModelVersion))),
        ),
      ),
      map(subModels => {
        const models = Array.from(subModels.values()).map(x => ({
          guid: x.subModelGuid,
          name: x.subModelName,
          version: x.subModelVersion,
        }));

        return libraryAction.setLibraryModels({ models });
      }),
    ),
  );

  public readonly builderLabelsModeDeactivated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(builderLabelsModeDeactivated),
      map(() => graphActions.clearHighlights()),
    ),
  );

  public readonly builderReasoningModeDeactivated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(builderReasoningModeDeactivated),
      map(() => reasoningActions.resetReasoning()),
    ),
  );

  public readonly changeBuilderModeHandler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBuilderMode),
      pairwise(),
      map(([{ mode: prev }]) => {
        // prettier-ignore
        switch (prev) {
          case ModelBuilderMode.Edit: return builderEditModeDeactivated();
          case ModelBuilderMode.LabelEdit: return builderLabelsModeDeactivated();
          case ModelBuilderMode.Reasoning: return builderReasoningModeDeactivated();
        }
      }),
    ),
  );

  public readonly checkOnObsolete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.completed),
      map(() => checkObsoleteConceptsExist()),
    ),
  );

  public readonly conceptPropertiesChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeConceptProperty),
      concatLatestFrom(() => this.store.select(selectModelMap)),
      concatMap(([{ ids, changes }, model]) =>
        from(ids).pipe(
          concatMap(id => {
            // Case one: the function is no longer exposed
            if (changes.exposed === false) {
              // If it is exposed and it is a provisional actuator,
              // then we need to reset its `actuatorType` and `actuatorTag` properties
              if (
                it(
                  isExposed(model.get(id).exposed),
                  () => model.get(id).actuatorType === 'provisional',
                )
              ) {
                // We ask the user for confirmation for each function (id)
                // and create a separate action (updateConceptProperties) if it is approved.
                // Thus, we produce an array of actions instead of a single one
                return this.confirmationService
                  .confirm({
                    message: `Clearing the exposure status will also clear the provisional actuator property
                      for the ${getConceptFullName(model.get(id).concept)} function with id ${id}.`,
                    options: { confirm: 'Confirm' },
                  })
                  .pipe(
                    filter(x => x === ConfirmationOption.Confirm),
                    map(() => updateConceptProperties({ ids: [id], changes })),
                  );
              }
            }

            return of(updateConceptProperties({ ids: [id], changes }));
          }),
        ),
      ),
    ),
  );

  public readonly disableEditing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelFileSuccess),
      map(({ version }) => setVersionOpened({ flag: version != null })),
    ),
  );

  public readonly exportJsonFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportJsonFile),
        tap(({ data }) => {
          if (environment.output === OutputStrategy.Console) {
            console.log(data);
          }
        }),
        concatLatestFrom(() => this.store.select(selectProjectName)),
        tap(([{ data, suffix }, projectName]) => {
          if (environment.output === OutputStrategy.File) {
            const json = JSON.stringify(data);
            const blob = new Blob([json], { type: 'application/json' });
            const name = `${projectName}-${getTimestamp()}${suffix}.json`;

            saveAs(blob, name, true);
          }
        }),
      ),
    { dispatch: false },
  );

  public readonly fetchGenericTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchLibraryModelDetailsSucceeded),
      switchMap(({ subModel: { guid, version } }) =>
        this.modelApiService.loadGenericTags(guid, version).pipe(
          map(({ tagging }) =>
            modelApiActions.fetchGenericTagsSucceeded({ guid, version, data: tagging }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(modelApiActions.fetchGenericTagsFault({ message: error.error?.detail })),
          ),
        ),
      ),
    ),
  );

  // After the model has been imported,
  // we need to fetch the details of the library models
  // that are being used in this imported model.
  public readonly getInformationAboutSubModels = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.completed, portalActions.saveModelDataSuccess),
      concatLatestFrom(() => [this.store.select(selectModelFlat([]))]),
      map(([action, model]) => modelApiActions.resolveModelTree({ model })),
    ),
  );

  public readonly importModelFromFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importModelFromFile),
      map(({ data }) => {
        const json = JSON.parse(data as string);
        const model = json.filter(({ concept }) => concept !== 'meta');

        return setImportedModel({ model });
      }),
    ),
  );

  public readonly importModelMetadataFromFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importModelFromFile),
      map(({ data }) => {
        const json = JSON.parse(data as string);
        const metadata = json.find(({ concept }) => concept === 'meta');

        return setModelMetadata({ metadata });
      }),
    ),
  );

  public readonly gotoBuilderEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gotoBuilderEditMode),
      concatLatestFrom(() => this.store.select(selectMode)),
      filter(([action, mode]) => mode !== ModelBuilderMode.Edit),
      map(([action, mode]) => gotoBuilderEditModeResolved()),
    ),
  );

  public readonly gotoBuilderEditModeResolved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gotoBuilderEditModeResolved),
      map(() => setBuilderMode({ mode: ModelBuilderMode.Edit })),
    ),
  );

  public readonly gotoBuilderLabelsMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gotoLabelsMode),
      concatLatestFrom(() => this.store.select(selectMode)),
      filter(([action, mode]) => mode !== ModelBuilderMode.LabelEdit),
      map(() => gotoLabelsModeResolved()),
    ),
  );

  public readonly gotoBuilderLabelsResolved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gotoLabelsModeResolved),
      map(() => setBuilderMode({ mode: ModelBuilderMode.LabelEdit })),
    ),
  );

  public readonly gotoBuilderReasoning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gotoReasoningMode),
      concatLatestFrom(() => [this.store.select(selectMode), this.store.select(selectMfmModel)]),
      // TODO: Temporary fix to avoid runtime errors during reasoning
      // when Sub-Models are involved.
      // In this case we disable reasoning and show a notification
      filter(([action, mode, model]) => {
        if (model.some(({ concept }) => it(isSUB(() => concept)))) {
          this.notificationService.info('Reasoning is not supported for Sub-Models');

          return false;
        }

        return mode !== ModelBuilderMode.Reasoning;
      }),
      map(() => graphActions.validateModelBeforeReasoning()),
    ),
  );

  public readonly gotoBuilderReasoningRejected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gotoReasoningModeRejected),
      tap(() => this.notificationService.error('Model validation returned errors/warnings')),
      map(({ result }) => setModelValidationResult({ result })),
    ),
  );

  public readonly gotoBuilderReasoningResolved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gotoReasoningModeResolved),
      map(() => setBuilderMode({ mode: ModelBuilderMode.Reasoning })),
    ),
  );

  // An effect that dispatches an action when a single submodel is selected,
  // contains the selected submodel (concept), or null if no submodel is selected.
  public readonly dispatchSingleSubModelSelected$ = createEffect(() =>
    this.store.select(selectSelectedMfmModel).pipe(
      map(({ ids, entities: [first] }) => {
        if (ids.length === 1) {
          if (it(isSUB(() => first.concept))) {
            return first;
          }
        }

        return null;
      }),
      distinctUntilChanged((p, c) => p === c || p?.id === c?.id),
      filter(concept => (concept?.subModelGuid ?? '') !== ''),
      map(concept => modelBuilderAction.singleSubModelSelected({ concept })),
    ),
  );

  public readonly handleSingleSubModelUpgradeAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelBuilderAction.singleSubModelSelected),
      map(({ concept }) => portalActions.fetchModelVersions({ id: concept.subModelGuid })),
    ),
  );

  public readonly handleSingleSubModelTagging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelBuilderAction.singleSubModelSelected),
      map(({ concept: { subModelGuid, subModelVersion } }) =>
        modelApiActions.fetchGenericTags({ guid: subModelGuid, version: subModelVersion }),
      ),
    ),
  );

  public readonly updateLibraryLatestState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(portalActions.fetchModelVersionsSuccess),
      concatLatestFrom(() => this.store.select(selectSubModels)),
      map(
        ([
          {
            id,
            versions: [{ version: latestVersion, fileTag: latestFileTag }, ...versions],
          },
          subModels,
        ]) =>
          Array.from(subModels.values())
            .filter(({ subModelGuid }) => subModelGuid === id)
            .reduce((acc, { id: conceptId, subModelVersion, subModelCanBeUpdated }) => {
              const latestTaggedVersion = latestFileTag
                ? latestVersion
                : versions.find(x => x.fileTag != null)?.version;

              return subModelCanBeUpdated
                ? [...acc, [conceptId, latestVersion !== subModelVersion]]
                : [...acc, [conceptId, latestTaggedVersion !== subModelVersion]];
            }, []),
      ),
      map(x =>
        patchModel({
          updates: x.map(([id, subModelUpdateAvailable]) => ({
            id,
            changes: { subModelUpdateAvailable },
          })),
        }),
      ),
    ),
  );

  public readonly notifyOfNewerVersionsAvailability$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(modelApiActions.resolveModelTreeSucceeded),
        map(({ response: { updates } }) => [...updates.controlled, ...updates.uncontrolled]),
        filter(updates => updates.length > 0),
        tap(updates => {
          this.notificationService.message(
            updates.map(x => x.detail).join('\n'),
            'Available updates',
            true,
          );
        }),
      ),
    { dispatch: false },
  );

  public readonly notifyOfOutdatedSubModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelTreeSucceeded),
      tap(({ response }) => {
        if (response.no_version.length > 0) {
          this.notificationService.message(
            `The following sub-models are missing valid version info (update from old format):
            ${response.no_version.map(x => x.detail).join('\n')}\n
            To resolve, select each submodel in turn and either:
            - right click and "Update" to use the most recent version of the referenced model
            - copy a reference from the Portal "file details" and paste in the "Model Details" field of the properties to reference a specific version`,
            'Warning',
            true,
          );
        }
      }),
      map(({ response }) =>
        setInvalidSubModels({
          ids: response.no_version.map(x => x.id),
        }),
      ),
    ),
  );

  public readonly resetConceptAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transformConcept),
      concatLatestFrom(({ id }) => this.store.select(selectMfmModelById(id))),
      map(([{ id, newType }, concept]) => {
        const updated = intersection(
          new Set(getConceptAttributes(newType)),
          new Set(
            getConceptAttributes(concept.concept, { processVariable: concept.processVariable }),
          ),
        ).reduce((c, attr) => Object.assign(c, { [attr]: concept[attr] }), {
          ...getDefaultConcept(),
          ...getDefaults(newType),
        });

        return Object.assign(updated, { concept: newType });
      }),
      map(concept => updateConceptProperties({ ids: [concept.id], changes: concept })),
    ),
  );

  public readonly setProject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setProject),
        tap(({ project }) => this.titleService.updateTitle(project.name)),
      ),
    { dispatch: false },
  );

  public readonly updateGraphCellStyles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applyStash, updateConceptProperties, updateModel),
      map(() => graphActions.updateGraphCellStyles()),
    ),
  );

  public readonly updateLibraryModelDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchLibraryModelDetailsSucceeded),
      // prettier-ignore
      map(
        ({
          subModel: { asset, comment, input, folder, guid, name, output, tag, sinks, sources, version },
        }) =>
          libraryAction.setLibraryModel({
            model: { asset, comment, input, folder, guid, name, output, tag, sinks, sources, version},
          }),
      ),
    ),
  );

  public readonly updateModelAfterImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.createModelCompleted),
      map(({ model }) => setMfmModel({ model })),
    ),
  );

  public readonly updateSubModelDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchLibraryModelDetailsSucceeded),
      map(({ tag, conceptId, subModel, latest }) =>
        updateConceptProperties({
          ids: [conceptId],
          changes: {
            subModelGuid: subModel.guid,
            subModelName: subModel.name,
            subModelVersion: subModel.version,
            // The model can be updated if a tag wasn't provided in the Properties Editor,
            // or if it differs from the one being fetched.
            subModelCanBeUpdated: tag.length > 0 ? subModel.tag !== tag : true,
            subModelTerminals: {
              inputs: subModel.input,
              outputs: subModel.output,
              sinks: subModel.sinks,
              sources: subModel.sources,
            },
            subModelUpdateAvailable: latest === false,
          },
        }),
      ),
    ),
  );

  /**
   * When a Sub-Model is updated, we need to update its terminals
   * (inputs, outputs, sinks, sources) in the graph.
   * For the MFM concept representing exposed function,
   * we need to update its `label` and `concept`.
   * Thus, users will see the updated label when hovering over it
   *
   * @see /src/app/common/utils/mx-graph-util.ts
   */
  public readonly updateSubModelTerminals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.fetchLibraryModelDetailsSucceeded),
      map(({ conceptId, subModel }) => {
        // Create an array of updates for all exposed functions.
        // Each `update` object contains the `id` of the exposed function
        // and its new `label` and `concept`
        const updates = [
          ...subModel.input,
          ...subModel.output,
          ...subModel.sinks,
          ...subModel.sources,
        ].map(({ id, concept, badge }) => ({
          id: `${conceptId}.${id}`,
          changes: { concept, label: badge.join('\t') },
        }));

        return patchModel({ updates });
      }),
    ),
  );

  /**
   * When a Barrier or Transport is no longer an Actuator,
   * we reset its attribute `actuatorTag`
   */
  public readonly processActuatorsResetActuatorTag$ = createEffect(() =>
    this.store.select(selectModelActuators).pipe(
      map(({ all }) => all),
      // Wait until a set of Actuators changed
      distinctUntilSetChanged(),
      concatLatestFrom(() => this.store.select(selectModelMap)),
      // Get id of MFM function that is no longer an Actuator
      map(([actuators, model]) =>
        Array.from(model.values())
          .filter(x =>
            it(
              or(
                isBAR(() => x.concept),
                isTRA(() => x.concept),
              ),
              not(() => actuators.has(x.id)),
              not(() => x.actuatorType === 'provisional'),
            ),
          )
          .map(x => x.id),
      ),
      // If we have such MFM functions
      filter(ids => ids.length > 0),
      // then reset the ActuatorTag for them
      map(ids => updateConceptProperties({ ids, changes: { actuatorTag: '' } })),
    ),
  );

  /**
   * When a Barrier or Transport that was a process variable becomes an Actuator,
   * we reset its attributes `instrumentTag` and processVariable`,
   * and notify users that it's happened
   */
  public readonly processActuators$ = createEffect(() =>
    this.store.select(selectModelActuators).pipe(
      map(({ all }) => all),
      // Wait until a set of Actuators changed
      distinctUntilSetChanged(),
      concatLatestFrom(() => this.store.select(selectModelMap)),
      map(([actuators, model]) =>
        Array.from(actuators)
          .map(id => model.get(id))
          .filter(({ processVariable }) => it(isProcessVariable(processVariable))),
      ),
      filter(actuators => actuators.length > 0),
      tap(actuators => {
        const actuatorsNames = actuators
          .map(({ id, label }) => `${label.length > 0 ? `${label} #${id}` : `#${id}`}`)
          .join('\n');

        // Notify the user that some of the virtual process variables became an `actuator`.
        // That means that they no longer are instruments, and `instrumentTag` value has been reset
        this.notificationService.message(
          `The following instruments have become an Actuator:
            ${actuatorsNames}`,
          'Warning',
          true,
        );
      }),
      map(actuators =>
        // Reset some attributes
        updateConceptProperties({
          ids: actuators.map(x => x.id),
          changes: { instrumentTag: '', processVariable: false },
        }),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly confirmationService: ConfirmationService,
    private readonly modelApiService: ModelApiService,
    private readonly notificationService: NotificationService,
    private readonly store: Store,
    private readonly titleService: TitleService,
  ) {}
}

import { MonoTypeOperatorFunction, Observable, pipe, UnaryFunction } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  pairwise,
  scan,
  startWith,
  tap,
} from 'rxjs/operators';
import { compareMaps } from './map-util';
import { intersection } from './set-util';

/**
 * Emits a notification from the source Observable only after a particular time span has passed without another source emission,
 * with an exact number of values were emitted during that time.
 *
 * @param dueTime the timeout duration in milliseconds for the window of time required to wait for emission silence before emitting the most recent source value.
 * @returns MonoTypeOperatorFunction
 */
export const debounceCounter =
  (dueTime: number): MonoTypeOperatorFunction<number> =>
  (source: Observable<unknown>): Observable<number> =>
    new Observable(observer =>
      source
        .pipe(
          scan(acc => acc + 1, 0),
          debounceTime(dueTime),
          startWith(0),
          pairwise(),
          map(([previous, current]) => current - previous),
        )
        .subscribe({
          next: x => {
            observer.next(x);
          },
          error: err => {
            observer.error(err);
          },
          complete: () => {
            observer.complete();
          },
        }),
    );

export const distinctUntilMapChanged = <T>(
  compareFn?: (aValue: T, bValue: T) => boolean,
): UnaryFunction<Observable<Map<string, T>>, Observable<Map<string, T>>> =>
  pipe(distinctUntilChanged((a, b) => compareMaps(a, b, compareFn)));

export const distinctUntilSetChanged = <T>(): UnaryFunction<
  Observable<Set<T>>,
  Observable<Set<T>>
> =>
  pipe(distinctUntilChanged((a, b) => a.size === b.size && intersection(a, b).length === a.size));

export const trace = <T>(hint: string = ''): UnaryFunction<Observable<T>, Observable<T>> =>
  pipe(tap(v => console.log(hint, v)));

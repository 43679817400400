import { Injectable } from '@angular/core';
import { Observable, startWith, Subject } from 'rxjs';

type TaskLabel = string;
type TaskId = string;

/**
 * Some activities might take a quite long time,
 * and they are not visible to the users. It might confuse.
 * This service is managing such tasks.
 * You can add a 'task' that is going to be in progress, and then 'complete' it.
 * This Service provides data for TaskMonitorComponent
 * that's aim is to visualize all that running 'tasks'
 */
@Injectable()
export class TaskMonitorService {
  public readonly tasks$: Observable<[TaskId, TaskLabel][]>;

  private readonly tasksSource = new Subject<[TaskId, TaskLabel][]>();
  private readonly tasks = new Map<TaskId, TaskLabel>([]);

  constructor() {
    this.tasks$ = this.tasksSource.asObservable().pipe(startWith([]));
  }

  public addTask(correlationId: TaskId, label = ''): void {
    this.tasks.set(correlationId, label);
    this.tasksSource.next(Array.from(this.tasks.entries()).reverse());
  }

  public completeTask(correlationId: TaskId): void {
    this.tasks.delete(correlationId);
    this.tasksSource.next(Array.from(this.tasks.entries()).reverse());
  }
}

import { createReducer, on } from '@ngrx/store';
import { ModelFile } from '@workbench/common/models/model-file.model';
import { exist, it } from '@workbench/common/utils/logical-utility';
import { Guid } from '@workbench/core/structs/guid';
import { ModelBuilderMode } from '@workbench/model-project/model-builder-mode.enum';
import { ExtendedSyntaxIssues } from '@workbench/multilevel-flow-modeling/syntax-issues-section/extended-syntax-issue.model';
import produce from 'immer';
import * as fromCausalAnalysisDescription from './causal-analysis-description.reducer';
import { importActions } from './import-model.actions';
import * as fromMfmConcept from './mfm-concept.reducer';
import {
  applyStash,
  copySelection,
  patchModel,
  setBuilderMode,
  setCausalAnalysisDescription,
  setImportedModel,
  setInvalidSubModels,
  setMfmModel,
  setModelMetadata,
  setModelValidationResult,
  setPermissions,
  setPointedConcept,
  setProject,
  setSelectedMfmModelItems as setSelectedConcepts,
  setVersionOpened,
  stashConcept,
  updateCausalAnalysisDescription,
  updateConceptProperties,
  updateModel,
} from './model-builder.actions';
import { portalActions } from './portal.actions';

interface ModelMetadata {
  [key: string]: unknown;
  id: '1';
  concept: 'meta';
  workbench?: Partial<{
    viewport: {
      x: number;
      y: number;
      scale: number;
    };
  }>;
}

export const initialState: ModelBuilderState = {
  causalAnalysisDescription: fromCausalAnalysisDescription.initialState,
  concepts: fromMfmConcept.initialState,
  id: null,
  invalidSubModels: [],
  metadata: { id: '1', concept: 'meta' },
  mode: ModelBuilderMode.Edit,
  permissions: { delete: false, modify: false, read: false, write: false },
  project: null,
  syntaxIssues: [],
  versionOpened: false,
};

export interface ModelBuilderState {
  causalAnalysisDescription: fromCausalAnalysisDescription.CausalAnalysisDescriptionState;
  concepts: fromMfmConcept.MfmConceptState;
  id: number | null;
  invalidSubModels: Guid[];
  metadata: ModelMetadata;
  mode: ModelBuilderMode;
  permissions: { delete: boolean; modify: boolean; read: boolean; write: boolean };
  project: (ModelFile & { lock?: { id: number; name: string } }) | null;
  syntaxIssues: ExtendedSyntaxIssues;
  versionOpened: boolean;
  importedModel?: unknown;
}

export const modelBuilderReducer = createReducer(
  initialState,
  on(
    applyStash,
    produce((state, action) => {
      state.concepts = fromMfmConcept.adapter.updateOne(
        { id: action.id, changes: { extras: state.concepts.stashed.extras } },
        state.concepts,
      );
      if (action.clear === true) {
        delete state.concepts.stashed;
      }
    }),
  ),
  on(
    importActions.completed,
    produce(state => {
      delete state.importedModel;
    }),
  ),
  on(
    portalActions.saveModelDataSuccess,
    produce((state, { modelFile }) => {
      state.project.version = modelFile.version;
    }),
  ),
  on(
    setBuilderMode,
    produce((state, { mode }) => {
      state.mode = mode;
    }),
  ),
  on(
    setCausalAnalysisDescription,
    produce((state, action) => {
      state.causalAnalysisDescription = fromCausalAnalysisDescription.adapter.setAll(
        action.data,
        state.causalAnalysisDescription,
      );
    }),
  ),
  on(
    setImportedModel,
    produce((state, action) => {
      state.importedModel = action.model;
    }),
  ),
  on(
    setInvalidSubModels,
    produce((state, { ids }) => {
      state.invalidSubModels = ids;
    }),
  ),
  on(
    setMfmModel,
    produce((state, action) => {
      state.concepts = fromMfmConcept.adapter.setAll(action.model, state.concepts);
    }),
  ),
  on(
    setModelMetadata,
    produce((state, { metadata }) => {
      // `id` and `concept` are required fields with a fixed value
      state.metadata = {
        ...((metadata as object) ?? {}),
        id: '1',
        concept: 'meta',
      };
    }),
  ),
  on(
    setModelValidationResult,
    produce((state, action) => {
      state.syntaxIssues = action.result;
    }),
  ),
  on(
    setPermissions,
    produce((state, { permission }) => {
      Object.assign(state.permissions, permission);
    }),
  ),
  on(
    setPointedConcept,
    produce((state, { id }) => {
      state.concepts.pointedId = id;
    }),
  ),
  on(
    setProject,
    produce((state, { project }) => {
      if (it(exist(() => project.id))) {
        state.concepts.selectedId = [];
        state.id = project.id;
      }
      state.project = { ...state.project, ...project };
    }),
  ),
  on(
    setSelectedConcepts,
    produce((state, { ids }) => {
      state.concepts.selectedId = ids;
    }),
  ),
  on(
    setVersionOpened,
    produce((state, { flag }) => {
      state.versionOpened = flag;
    }),
  ),
  on(
    stashConcept,
    produce((state, { concept }) => {
      state.concepts.stashed = concept;
    }),
  ),
  on(
    copySelection,
    produce(state => {
      state.concepts.copied = state.concepts.selectedId.map(id => state.concepts.entities[id]);
    }),
  ),
  on(
    updateModel,
    produce((state, action) => {
      state.concepts = fromMfmConcept.adapter.upsertMany(action.updates, state.concepts);
    }),
  ),
  on(
    patchModel,
    produce((state, action) => {
      state.concepts = fromMfmConcept.adapter.updateMany(action.updates, state.concepts);
    }),
  ),
  on(
    updateConceptProperties,
    produce((state, action) => {
      state.concepts = fromMfmConcept.adapter.updateMany(
        action.ids.map(id => ({ id, changes: action.changes })),
        state.concepts,
      );
    }),
  ),
  on(
    updateCausalAnalysisDescription,
    produce((state, action) => {
      state.causalAnalysisDescription = fromCausalAnalysisDescription.adapter.upsertMany(
        action.changes,
        state.causalAnalysisDescription,
      );
    }),
  ),
  on(
    setCausalAnalysisDescription,
    produce((state, action) => {
      state.causalAnalysisDescription = fromCausalAnalysisDescription.adapter.setAll(
        action.data,
        state.causalAnalysisDescription,
      );
    }),
  ),
);

import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NotificationService } from '@workbench/core/services/notification.service';
import { SharedModule } from 'app/shared/shared.module';
import { ClipboardService } from './services/clipboard.service';
import { ConfirmationService } from './services/confirmation.service';
import { TaskMonitorService } from './services/task-monitor.service';
import { TitleService } from './services/title.service';

@NgModule({
  imports: [SharedModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the RootModule only');
    }
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ClipboardService,
        ConfirmationService,
        NotificationService,
        TaskMonitorService,
        TitleService,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
      ],
    };
  }
}

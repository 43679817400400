import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ReasoningFaultKind } from '@workbench/business-logic/enums/reasoning-fault-kind.enum';
import {
  copy,
  copyItemsToCells,
  deserializeCells,
} from '@workbench/business-logic/services/cells-clipboard-service';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { SeverityLevel } from '@workbench/common/enums/severity-level.enum';
import {
  CausalAnalysisPropagationPath,
  CausalAnalysisPropagationPathNode,
} from '@workbench/common/models/causal-reasoning-path.model';
import { ActuatorKindStyle } from '@workbench/common/types/actuator-kind.type';
import {
  isOfflineProcessVariable,
  isProcessVariable,
} from '@workbench/common/types/process-variable-value.type';
import { SensorStateValue } from '@workbench/common/types/sensor-state.type';
import { toMap } from '@workbench/common/utils/array-util';
import { and, exist, idt, it, not, or, Predicate } from '@workbench/common/utils/logical-utility';
import { addLabelToEdge, updateSubModel } from '@workbench/common/utils/mx-graph-util';
import { debounceCounter } from '@workbench/common/utils/rxjs-util';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { logTime } from '@workbench/common/utils/testing-util';
import { CanvasTooltipService } from '@workbench/core/services/canvas-tooltip.service';
import { ClipboardService } from '@workbench/core/services/clipboard.service';
import {
  ConfirmationOption,
  ConfirmationService,
} from '@workbench/core/services/confirmation.service';
import { NotificationService } from '@workbench/core/services/notification.service';
import { mxConstants, mxEvent, mxgraph, mxUtils } from '@workbench/dts/mxg';
import { ModelBuilderMode } from '@workbench/model-project/model-builder-mode.enum';
import { ModelBuilder } from '@workbench/multilevel-flow-modeling/builders/model-builder';
import { connectionProcessor } from '@workbench/multilevel-flow-modeling/core/connection-processor';
import {
  getDefaultConcept,
  getDefaults,
  hasAttribute,
} from '@workbench/multilevel-flow-modeling/core/mfm-attributes';
import { getConceptLabel } from '@workbench/multilevel-flow-modeling/core/mfm-concept-label';
import {
  isAC,
  isControlFunction,
  isMEQ,
  isRelation,
  isStructure,
  isSUB,
  isSYS,
  isTRA,
  isTXT,
} from '@workbench/multilevel-flow-modeling/core/mfm-core';
import {
  getOppositeEnd,
  hasOppositeDirection,
  isConnected,
} from '@workbench/multilevel-flow-modeling/core/mfm-util';
import { GraphService } from '@workbench/multilevel-flow-modeling/graph.service';
import { mfmConceptConfigurationMapper } from '@workbench/multilevel-flow-modeling/mappings';
import { CellBuilder } from '@workbench/mx-graph/cell-builder';
import { CustomGraphView } from '@workbench/mx-graph/extensions/custom-graph-view';
import {
  styleActuator,
  styleExposed,
  styleHazardLevel,
  styleLogicalGateValue,
  styleObjectiveCategory,
  styleProcessVariable,
  styleReliefSystem,
  styleSensorState,
} from '@workbench/mx-graph/extensions/custom-style';
import { assignStyle, getStyle, removeStyle } from '@workbench/mx-graph/utils/style-util';
import { ConfirmationData } from '@workbench/shared/confirmation/confirmation.component';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { runCausalAnalysis } from '../causal-analysis/causal-analysis.actions';
import { selectCausalTree } from '../causal-analysis/causal-analysis.selectors';
import { MfmConceptConfiguration } from '../model-builder/mfm-model.model';
import * as modelBuilderAction from '../model-builder/model-builder.actions';
import {
  ModelActuators,
  selectHasInvalidSubModels,
  selectMfmModel,
  selectMode,
  selectModelActuators,
  selectModelExposed,
  selectModelMap,
  selectModelMetadata,
  selectModelMetadataUpdated,
  selectModelProcessVariables,
  selectModelReliefSystems,
  selectModelSensors,
  selectPointedConcept,
  selectSelectedMfmModel,
  selectStashedConcept,
  selectSubModels,
} from '../model-builder/selectors/model-builder.selectors';
import * as reasoningAction from '../reasoning/reasoning.actions';
import { selectCurrentItem } from '../reasoning/reasoning.selectors';

import { MfmModel } from '@workbench/business-logic/models/mfm.model';
import { getTerminalIds } from '@workbench/common/models/library-component.model';
import { union } from '@workbench/common/utils/set-util';
import { modelApiActions } from '../model-api/model-api.actions';
import {
  selectImportedModel,
  selectModelFlat,
} from '../model-builder/selectors/model-builder.selectors';
import {
  buildModelForCausalAnalysis,
  clearHighlights,
  deleteSelectedCells,
  exportToFile,
  exportToFileExtendedModel,
  exportToPortal,
  focusGraphCell,
  graphCellPointed,
  graphChanged,
  graphInitialized,
  graphZoomActual,
  graphZoomAll,
  graphZoomIn,
  graphZoomOut,
  graphZoomSystem,
  highlightCausalPaths,
  highlightEndConsequencesTree,
  highlightRootCausesTree,
  runReasoning,
  setVisibleMfmEntities,
  toggleGridDisplay,
  updateGraphCellStyles,
  validateModel,
  validateModelBeforeReasoning,
} from './graph.actions';
import { createGraphCells } from './import-model';
import { importActions } from './import-model.actions';
import { portalActions } from './portal.actions';

//#region Constants
const arrowColor = (): string => '#FFD060';
const endConsequenceColor = (): string => '#1A9CDB';
const endConsequenceOutlineColor = (): string => '#78D9FF';
const faultColor = (): string => '#FFD060';
const faultOutlineColor = (): string => '#FFEAA3';
const inspectionPointColor = (): string => '#FFB300';
const preventiveColor = (): string => '#33B1FF';
const rootCauseColor = (): string => '#53B876';
const rootCauseOutlineColor = (): string => '#83E19E';
const zoomSystemLevel = (): number => 0.4;

const getShape = (state: ReasoningFaultKind, concept?: ResourceConcept): string | undefined => {
  // prettier-ignore
  switch (state) {
    case ReasoningFaultKind.Breach: return 'reasoning-fault-kind-breach';
    case ReasoningFaultKind.False: return 'reasoning-fault-kind-false';
    case ReasoningFaultKind.High:
      return it(isControlFunction(concept))
        ? 'reasoning-fault-kind-control-high'
        : 'reasoning-fault-kind-high';
    case ReasoningFaultKind.Low: {
      return it(isControlFunction(concept))
        ? 'reasoning-fault-kind-control-low'
        : 'reasoning-fault-kind-low';
    }
    case ReasoningFaultKind.Normal: return 'reasoning-fault-kind-normal';
    case ReasoningFaultKind.True: return 'reasoning-fault-kind-true';
    default: throw new Error(`Argument out of bounds. state=${state}`);
  }
};

//#endregion

@Injectable()
export class GraphEffects {
  // Display a tooltip when the user is pointing at the sub-model port.
  public readonly showTooltip = createEffect(
    () =>
      this.store.select(selectPointedConcept).pipe(
        // Hide the tooltip.
        tap(() => {
          this.canvasTooltipService.hideTooltip();
        }),
        // Stop propagation if the user is not pointing at any concept.
        filter(pointedConcept => pointedConcept !== null),
        concatLatestFrom(() => this.store.select(selectModelMap)),
        tap(([concept, model]) => {
          const graph = this.graphService.getGraph();

          if (it(isSUB(() => model.get(concept.parentId)?.concept))) {
            const cell = graph.model.getCell(concept.id);
            const cellBounds = graph.view.getBoundingBox(graph.view.getState(cell));

            if (cell.getValue().length > 0) {
              this.canvasTooltipService.showTooltip(cellBounds, cell.getValue().split('\t'));
            }
          }
          if (it(isTXT(() => concept.concept))) {
            const cell = graph.model.getCell(concept.id);
            const cellBounds = graph.view.getBoundingBox(graph.view.getState(cell));

            if (concept.comment.length > 0) {
              const text =
                concept.comment.length > 250
                  ? concept.comment.substring(0, 250) + '...'
                  : concept.comment;

              this.canvasTooltipService.showTooltip(cellBounds, text);
            }
          }
        }),
      ),
    { dispatch: false },
  );

  public readonly clearHighlights$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearHighlights),
        tap(() => {
          this.graphService.getGraph().resetAllCustomStyles();
        }),
      ),
    { dispatch: false },
  );

  public readonly copySelection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(modelBuilderAction.copySelection),
        concatLatestFrom(() => this.store.select(selectSelectedMfmModel)),
        map(([action, { ids, entities }]) => {
          // A Set of selected items' ids.
          const selected = new Set(ids);
          // A Set of ids of terminals of the selected sub-models.
          // It is necessary to understand whether a relation is connected
          // with the sub-model's terminal.
          const selectedSubModelTerminals = entities
            .filter(({ concept, subModelTerminals }) =>
              it(
                isSUB(() => concept),
                exist(() => subModelTerminals),
              ),
            )
            .reduce(
              (
                terminals,
                { id, subModelTerminals: { inputs: input, outputs: output, sinks, sources } },
              ) => new Set(union(terminals, getTerminalIds(id, { input, output, sinks, sources }))),
              new Set<string>(),
            );

          // Filter what could be copied.
          // A relation that:
          // - is not connected with both ends
          // - has a connection to a vertex that is not included in the selection
          // - has a special connection to structure that is not included in the selection
          // is excluded and not been copied
          return entities.filter(x =>
            it(
              or(
                not(isRelation(() => x.concept)),
                and(
                  or(
                    // The relation is connected with the item that is included in the selection
                    () => selected.has(x.linkSourceId),
                    // or the relation is connected with the sub-model's terminal
                    () => selectedSubModelTerminals.has(x.linkSourceId),
                  ),
                  or(
                    // The relation is connected with the item that is included in the selection
                    () => selected.has(x.linkTargetId),
                    // or the relation is connected with the sub-model's terminal
                    () => selectedSubModelTerminals.has(x.linkTargetId),
                  ),
                  or(
                    () => getNotEmpty(x.mainFunction?.sourceId) === '',
                    () => selected.has(x.mainFunction.sourceId),
                  ),
                  or(
                    () => getNotEmpty(x.mainFunction?.targetId) === '',
                    () => selected.has(x.mainFunction.targetId),
                  ),
                ),
              ),
            ),
          );
        }),
        map(entities =>
          entities.map(({ id }) => this.graphService.getGraph().getModel().getCell(id)),
        ),
        map(cells => copy(cells, this.graphService.getGraph())),
        // Notifying on a number of copied items
        tap(cells => this.notificationService.info(`${cells.length} item(s) have been copied`)),
        concatLatestFrom(() => this.store.select(selectSelectedMfmModel)),
        switchMap(([cells, { entities: original }]) =>
          this.clipboardService.write(JSON.stringify({ cells, original })),
        ),
      ),
    { dispatch: false },
  );

  public readonly pasteSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelBuilderAction.pasteSelection),
      concatLatestFrom(() => this.store.select(selectMode)),
      filter(([action, mode]) => mode === ModelBuilderMode.Edit),
      switchMap(() => this.clipboardService.read()),
      switchMap(buffer =>
        deserializeCells(buffer).pipe(
          catchError(
            () => (
              this.notificationService.error(
                'An error occurred during the copy/paste. Please try again',
              ),
              of({ cells: [], original: new Map() })
            ),
          ),
        ),
      ),
      withLatestFrom(this.graphService.mousePointer$),
      map(([{ cells: items, original }, [x, y]]) => {
        const scale = this.graphService.getGraph().getView().scale;
        const { x: tx, y: ty } = this.graphService.getGraph().getView().translate;
        const pointer = { x: x / scale - tx, y: y / scale - ty };
        const cells = copyItemsToCells(this.graphService.getGraph(), items, original, pointer);

        return { cells, original };
      }),
      map(({ cells, original }) => {
        const updates = cells.map(({ copyItem, mfm }) =>
          Object.assign<unknown, MfmConceptConfiguration, Partial<MfmConceptConfiguration>>(
            Object.create(null),
            original.get(copyItem.mfm.id),
            {
              id: mfm.id,
              linkSourceId: mfm.linkSourceId,
              linkSourcePortName: mfm.linkSourcePortName,
              linkTargetId: mfm.linkTargetId,
              linkTargetPortName: mfm.linkTargetPortName,
              mainFunction: mfm.mainFunction
                ? {
                    sourceId: mfm.mainFunction.sourceId ?? '',
                    targetId: mfm.mainFunction.targetId ?? '',
                  }
                : null,
              parentId: mfm.parentId,
              // Do not copy the actuatorTag.
              // Since this is the attribute of the Actuator only,
              // and will be recognized later.
              // At this point, we don't know whether this new (pasted) item is an Actuator.
              actuatorTag: '',
            },
          ),
        );

        //  we don't need information for copy paste after paste
        cells.forEach(cell => {
          delete cell.copyItem;
        });

        return modelBuilderAction.updateModel({ updates });
      }),
    ),
  );

  public readonly exportModelToFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportToFile),
      concatLatestFrom(() => [
        this.store.select(
          selectModelFlat(
            this.graphService.getCells(),
            this.graphService.getGraph().getDefaultParent().getId(),
          ),
        ),
        this.store.select(
          selectModelMetadataUpdated(
            this.graphService.getCells().filter(cell => it(isTXT(() => cell.mfm?.conceptId))),
            {
              x: this.graphService.getGraph().container.scrollLeft,
              y: this.graphService.getGraph().container.scrollTop,
              scale: this.graphService.getGraph().getView().scale,
            },
          ),
        ),
      ]),
      map(([action, model, metadata]) => [metadata, ...model]),
      map(model => modelBuilderAction.exportJsonFile({ data: model, suffix: '.model' })),
    ),
  );

  public readonly highlightRootCausesTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(highlightRootCausesTree),
      concatLatestFrom(action => [
        this.store.select(selectCausalTree({ rootCause: action.rootCause })),
      ]),
      map(([action, paths]) =>
        highlightCausalPaths({ paths, include: { inspectionPoints: true } }),
      ),
    ),
  );

  public readonly highlightEndConsequenceTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(highlightEndConsequencesTree),
      concatLatestFrom(action => [
        this.store.select(selectCausalTree({ endConsequence: action.endConsequence })),
      ]),
      map(([action, paths]) =>
        highlightCausalPaths({ paths, include: { preventiveActions: true } }),
      ),
    ),
  );

  public readonly highlightCausalPaths$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(highlightCausalPaths),
        concatLatestFrom(() => this.store.select(selectModelMap)),
        tap(([{ paths, include }, model]) => {
          this.highlightCausalTree(paths, model, include);
        }),
      ),
    { dispatch: false },
  );

  public readonly handleGraphChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(graphChanged),
      concatLatestFrom(() => this.store.select(selectModelMap)),
      map(([action, model]) => {
        const cells = this.graphService
          .getCells()
          .filter(cell => it(exist(() => cell.mfm)))
          // TODO: Needs to have a think about this line
          // .filter(cell => it(not(isSUB(() => cell?.parent?.mfm?.conceptId))))
          .map(cell => {
            const source = cell.getTerminal(true);
            const target = cell.getTerminal(false);

            cell.mfm.linkSourceId = getNotEmpty(source?.id);
            cell.mfm.linkTargetId = getNotEmpty(target?.id);
            cell.mfm.parentId =
              cell.parent && cell.parent !== this.graphService.getGraph().getDefaultParent()
                ? cell.parent.mfm.id
                : '';

            return cell.mfm;
          });

        return mfmConceptConfigurationMapper(cells).map(cellMfm =>
          Object.assign(
            getDefaultConcept(),
            getDefaults(cellMfm.concept),
            model.get(cellMfm.id) ?? {},
            cellMfm,
          ),
        );
      }),
      distinctUntilChanged((a, b) => compare(a, b).length === 0),
      map(model => modelBuilderAction.setMfmModel({ model })),
    ),
  );

  // When the model changes, we need to update
  // the corresponding mxCell's MfmModel.
  // This is done by listening to the changeConceptProperty action.
  // For now we only need to update the `label` property.
  // There are other places in the code where we rely on the `mxCell.mfm.label` value.
  public readonly handleModelChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(modelBuilderAction.changeConceptProperty),
        filter(({ changes }) => Object.keys(changes).includes('label')),
        tap(({ ids, changes }) => {
          ids.forEach(id => {
            const cell = this.graphService.getGraph().getModel().getCell(id);

            if (cell) {
              cell.mfm.label = changes.label;
            }
          });
        }),
      ),
    { dispatch: false },
  );

  public readonly handleActuatorsChange$ = createEffect(
    () =>
      this.store.select(selectModelActuators).pipe(
        pairwise(),
        tap(([prev, next]) => this.processActuators(prev, next)),
      ),
    { dispatch: false },
  );

  public readonly handleReliefSystemsChange$ = createEffect(
    () =>
      this.store.select(selectModelReliefSystems).pipe(
        pairwise(),
        tap(([prev, next]) => this.processReliefSystems(prev, next)),
      ),
    { dispatch: false },
  );

  public readonly handleExposedFunctionChange$ = createEffect(
    () =>
      this.store.select(selectModelExposed).pipe(
        pairwise(),
        tap(([prev, next]) => this.processExposedFunctions(prev, next)),
      ),
    { dispatch: false },
  );

  public readonly handleSensorsChange$ = createEffect(
    () =>
      this.store.select(selectModelSensors).pipe(
        pairwise(),
        tap(([prev, next]) => this.processSensors(prev, next)),
      ),
    { dispatch: false },
  );

  public readonly handleProcessVariablesChange$ = createEffect(
    () =>
      this.store.select(selectModelProcessVariables).pipe(
        pairwise(),
        concatLatestFrom(() => this.store.select(selectModelMap)),
        tap(([[prev, next], model]) => this.processProcessVariables(model, prev, next)),
      ),
    { dispatch: false },
  );

  public readonly handleObsoleteConceptsExistCheck$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(modelBuilderAction.checkObsoleteConceptsExist),
        concatLatestFrom(() => this.store.select(selectMfmModel)),
        tap(([action, model]) => {
          const modelHasObsoleteStructures = model.some(item =>
            it(
              or(
                isSYS(() => item.concept),
                isMEQ(() => item.concept),
              ),
            ),
          );

          if (modelHasObsoleteStructures) {
            this.notificationService.message(
              'Your model contains System or/and Equipment structures which are no longer used. In order to operate the model, You need to delete them from the model and re-group the elements using group attributes. Select the functions you want to include to the Equipment and create the Equipment Label & Tag instead of Equipment structure. Select the functions you want to include to the System and create the System Label & Tag instead of System structure.',
              'Warning',
              true,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  public readonly handleSubModels$ = createEffect(
    () =>
      this.store.select(selectSubModels).pipe(
        pairwise(),
        tap(([prev, next]) => {
          this.graphService.getGraph().getModel().beginUpdate();
          this.processSubModels(prev, next);
          this.graphService.getGraph().getModel().endUpdate();
        }),
      ),
    { dispatch: false },
  );

  public readonly handleConceptPropertiesChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateGraphCellStyles),
        concatLatestFrom(() => this.store.select(selectMfmModel)),
        map(([action, concepts]) =>
          concepts
            .filter(concept => {
              const cell = this.graphService.getGraph().getModel().getCell(concept.id);

              if (cell == null) {
                return false;
              }

              return [
                // update concepts shape
                tryUpdateConcept(cell, concept.concept),
                // update concepts label
                tryUpdateLabel(cell, concept),
                // update a 'special' relations label
                it(
                  isRelation(() => concept.concept),
                  exist(() => concept.mainFunction),
                  () =>
                    // TODO: Refresh the cell
                    tryUpdateSecondaryLabel(
                      cell,
                      concepts.find(({ id }) => id === concept.mainFunction.sourceId) ?? null,
                      concepts.find(({ id }) => id === concept.mainFunction.targetId) ?? null,
                    ),
                ),
                // update concepts 'logical or' value
                it(hasAttribute(concept.concept, 'logicGateVotes'), () =>
                  tryUpdateLogicGateValue(cell, concept.logicGateVotes),
                ),
                // update concepts 'category' style
                it(hasAttribute(concept.concept, 'category'), () =>
                  tryUpdateObjectiveCategoryStyle(cell, concept.category),
                ),
                // update concepts 'level' style
                it(hasAttribute(concept.concept, 'level'), () =>
                  tryUpdateHazardLevelStyle(cell, concept.level),
                ),
              ].some(x => x === true);
            })
            .map(({ id }) => id),
        ),
        tap(ids => {
          const graph = this.graphService.getGraph();

          graph.view.validate();
          ids.forEach(id => {
            const cell = graph.model.getCell(id);
            const s = graph.view.getState(cell);

            graph.cellRenderer.redraw(s);
            // If the cell has children,
            // it means that there could be a text cell (label) among them.
            // We should update the label as well.
            // For example, when a concept type or label has been changed,
            // and it (concept) has a special connection,
            // the label should be also updated
            if (cell.getChildCount() > 0) {
              cell.children
                .filter(({ style }) => getStyle(style, 'text') === '1')
                .map(x => graph.view.getState(x))
                .forEach(x => graph.cellRenderer.redraw(x));
            }
          });
          graph.view.refresh();
        }),
      ),
    { dispatch: false },
  );

  public readonly buildModelAndPerformCausalAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(buildModelForCausalAnalysis),
      concatLatestFrom(() => this.store.select(selectMfmModel)),
      map(([action, mfm]) => {
        const model = new ModelBuilder(mfm).build();

        return runCausalAnalysis({ model });
      }),
    ),
  );

  public readonly resolveModelAndExportToFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportToFileExtendedModel),
      concatLatestFrom(() => this.store.select(selectHasInvalidSubModels)),
      // TODO: This filter and notification is a temporary solution.
      // It should be refactored when the transition to the versioned models is accomplished.
      filter(([action, hasInvalidSubModels]) => {
        if (hasInvalidSubModels) {
          this.notificationService.error('Please fix all invalid sub-models before proceeding.');

          return false;
        }

        return true;
      }),
      concatLatestFrom(() =>
        this.store.select(
          selectModelFlat(
            this.graphService.getCells(),
            this.graphService.getGraph().getDefaultParent().getId(),
          ),
        ),
      ),
      map(([action, model]) => modelApiActions.resolveModelAndExport({ model })),
    ),
  );

  public readonly resolveModelAndExportSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modelApiActions.resolveModelAndExportSucceeded),
      concatLatestFrom(() =>
        this.store.select(
          selectModelMetadataUpdated(
            this.graphService.getCells().filter(cell => it(isTXT(() => cell.mfm?.conceptId))),
            {
              scale: this.graphService.getGraph().getView().scale,
              x: this.graphService.getGraph().container.scrollLeft,
              y: this.graphService.getGraph().container.scrollTop,
            },
          ),
        ),
      ),
      // The exported flat model contains the metadata.
      // The metadata is a union of the model's metadata and metadata received after the model resolution,
      // which may hold some additional properties.
      // We need to combine them into one object.
      map(([{ model }, metadata]) => {
        const resolvedMetadata = (model as { concept }[]).find(x => x.concept === 'meta') ?? {};
        const resolvedModel = (model as { concept }[]).filter(x => x.concept !== 'meta') ?? [];

        return [{ ...resolvedMetadata, ...metadata }, ...resolvedModel];
      }),
      map(data => modelBuilderAction.exportJsonFile({ data, suffix: '.flat' })),
    ),
  );

  public readonly resolveModelAndRunReasoning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runReasoning),
      concatLatestFrom(() =>
        this.store.select(
          selectModelFlat(
            this.graphService.getCells(),
            this.graphService.getGraph().getDefaultParent().getId(),
          ),
        ),
      ),
      map(([action, model]) => modelApiActions.resolveModelAndRunReasoning({ model })),
    ),
  );

  public readonly resolveModelAndValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateModel),
      concatLatestFrom(() =>
        this.store.select(
          selectModelFlat(
            this.graphService.getCells(),
            this.graphService.getGraph().getDefaultParent().getId(),
          ),
        ),
      ),
      map(([action, model]) => modelApiActions.resolveModelAndValidate({ model })),
    ),
  );

  public readonly validateModelBeforeReasoning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateModelBeforeReasoning),
      concatLatestFrom(() =>
        this.store.select(
          selectModelFlat(
            this.graphService.getCells(),
            this.graphService.getGraph().getDefaultParent().getId(),
          ),
        ),
      ),
      map(([action, model]) => modelApiActions.resolveModelAndValidateBeforeReasoning({ model })),
    ),
  );

  public readonly compileModelAndExportToPortal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportToPortal),
      concatLatestFrom(() => [
        this.store.select(
          selectModelFlat(
            this.graphService.getCells(),
            this.graphService.getGraph().getDefaultParent().getId(),
          ),
        ),
        this.store.select(
          selectModelMetadataUpdated(
            this.graphService.getCells().filter(cell => it(isTXT(() => cell.mfm?.conceptId))),
            {
              x: this.graphService.getGraph().container.scrollLeft,
              y: this.graphService.getGraph().container.scrollTop,
              scale: this.graphService.getGraph().getView().scale,
            },
          ),
        ),
      ]),
      map(([action, model, metadata]) => [metadata, ...model]),
      map(model => portalActions.saveModelData({ data: model })),
    ),
  );

  public readonly deleteSelectedCells$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSelectedCells),
      concatLatestFrom(() => [
        this.store.select(selectStashedConcept),
        this.store.select(selectSelectedMfmModel),
        this.store.select(selectModelMap),
      ]),
      // Confirmation guard in case users are about to remove item with 'extras'
      switchMap(([action, stashed, selection, model]) =>
        this.deleteExtrasInterceptor(stashed, action.cascade, model).pipe(
          map(option => ({ action, option, selection })),
        ),
      ),
      // Delete cells if action confirmed in some way
      tap(({ action, option }) => {
        if (option === ConfirmationOption.Reject || option === ConfirmationOption.Confirm) {
          this.graphDeleteSelectedCells(action.cascade);
        }
      }),
      // Dispatch action to stash concept if that option selected
      filter(({ option }) => option === ConfirmationOption.Reject),
      map(({ selection }) => {
        const [concept] = selection.entities;

        return modelBuilderAction.stashConcept({ concept });
      }),
    ),
  );

  public readonly focusGraphCell$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(focusGraphCell),
        tap(action => {
          const graph = this.graphService.getGraph();
          const cell = graph.getModel().getCell(action.id);

          graph.getSelectionModel().setCell(cell);
          graph.scrollCellToVisible(cell, true);
        }),
      ),
    { dispatch: false },
  );

  public readonly graphCellPointed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(graphCellPointed),
      map(({ id }) => modelBuilderAction.setPointedConcept({ id })),
    ),
  );

  public readonly graphInitializedLoadFromJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(graphInitialized),
      concatLatestFrom(() => this.store.select(selectImportedModel)),
      filter(([action, model]) => it(exist(() => model))),
      map(() => importActions.start()),
    ),
  );

  public readonly graphZoomActual$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(graphZoomActual),
        tap(() => {
          this.graphService.getGraph().zoomTo(1, true);
        }),
      ),
    { dispatch: false },
  );

  public readonly graphZoomAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(graphZoomAll),
        tap(() => {
          const graph = this.graphService.getGraph();

          //User story 9016:
          //"border" argument (40) is necessary to update container coordinates
          graph.fit(40);
          //container`s shifting adds padding between elements and viewport borders
          graph.container.scrollLeft -= 20;
          graph.container.scrollTop -= 20;

          graph.view.rendering = true;
          graph.refresh(null);
        }),
      ),
    { dispatch: false },
  );

  public readonly graphZoomIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(graphZoomIn),
        debounceCounter(300),
        tap(times => {
          const factor = Math.pow(this.graphService.getGraph().zoomFactor, times);

          this.graphService.getGraph().zoom(factor);
        }),
      ),
    { dispatch: false },
  );

  public readonly graphZoomOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(graphZoomOut),
        debounceCounter(300),
        tap(times => {
          const factor = 1 / Math.pow(this.graphService.getGraph().zoomFactor, times);

          this.graphService.getGraph().zoom(factor);
        }),
      ),
    { dispatch: false },
  );

  public readonly importModelCreateGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.createGraph),
      tap(() => console.log(logTime(), 'begin model import')),
      delay(0), // Without a `delay(0)`, we don't have time to update TaskMonitor with a new task
      concatLatestFrom(() => [
        this.store.select(selectModelMap),
        this.store.select(selectImportedModel),
        this.store.select(selectModelMetadata),
      ]),
      tap(([action, model, data, { notes = [] }]) => {
        const cells = createGraphCells(data, model);
        const graph = this.graphService.getGraph();

        // Clear hidden cells `map` to make every cell visible
        graph.hiddenCells.clear();
        graph.getModel().beginUpdate();
        graph.getModel().clear();

        // Add vertices to the graph
        cells.forEach(cell => {
          if (cell.isVertex()) {
            const { concept, logicGateVotes, category, level, subModelTerminals } = model.get(
              cell.id,
            );
            const { x, y, width, height } = cell.geometry;
            const { id, value, style } = cell;

            if (it(hasAttribute(concept, 'logicGateVotes'))) {
              tryUpdateLogicGateValue(cell, logicGateVotes);
            }
            // update concepts 'category' style
            if (it(hasAttribute(concept, 'category'))) {
              tryUpdateObjectiveCategoryStyle(cell, category);
            }
            // update concepts 'level' style
            if (it(hasAttribute(concept, 'level'))) {
              tryUpdateHazardLevelStyle(cell, level);
            }

            const vertex = graph.insertVertex(null, id, value, x, y, width, height, style);

            vertex.mfm = cell.mfm;

            // When we have a Sub-model.
            // It should be initialized if it has a specific model assigned.
            // It means that additional vertices (children mxCells) have to be created and added.
            // These vertices are the exposed MFM functions from the Sub-model.
            //
            // Pay attention to the order.
            // We update the vertex that was just created with `insertVertex`,
            // such that children cells are going to be properly linked with the parent Sub-model.
            if (it(hasAttribute(concept, 'subModelGuid'))) {
              tryUpdateSubModel(graph, vertex, { next: subModelTerminals });
            }
          }
        });
        cells.forEach(cell => {
          if (cell.isVertex()) {
            const v = graph.model.getCell(cell.id);
            const parent = graph.model.getCell(cell.mfm.parentId);

            if (parent) {
              graph.model.add(parent, v);
            }
          }

          if (cell.isEdge()) {
            const [source, edge, target] = connectionProcessor(
              graph.model.getCell(cell.mfm.linkSourceId),
              cell,
              graph.model.getCell(cell.mfm.linkTargetId),
              graph.model.getCell(graph.model.getCell(cell.mfm.linkSourceId)?.mfm.parentId),
              graph.model.getCell(graph.model.getCell(cell.mfm.linkTargetId)?.mfm.parentId),
            );

            const e = graph.insertEdge(null, edge.id, null, source, target, edge.getStyle());

            e.mfm = edge.mfm;
            e.setGeometry(edge.geometry);
          }
        });

        // Process labels for the special connections
        cells.forEach(cell => {
          const e = graph.model.getCell(cell.id);

          if (
            it(
              isRelation(() => e.mfm.conceptId),
              exist(() => e.mfm.mainFunction),
            )
          ) {
            if (it(exist(() => e.mfm.mainFunction.targetId))) {
              const { label, concept } = model.get(e.mfm.mainFunction.targetId);
              const edgeLabel = label === '' ? concept : `${label} (${concept})`;

              addLabelToEdge(e, graph, edgeLabel, false);
            }
            if (it(exist(() => e.mfm.mainFunction.sourceId))) {
              const { label, concept } = model.get(e.mfm.mainFunction.sourceId);
              const edgeLabel = label === '' ? concept : `${label} (${concept})`;

              addLabelToEdge(e, graph, edgeLabel, true);
            }
          }
        });
        // Process the notes to the model.
        // Notes come from the metadata as an array of geometry and comment.
        // We need to create a new cell for each note and add it to the graph.
        // We do it at the end because a note doesn't hold the 'id' property,
        // in this way we can avoid any identifier conflicts
        Array.from(notes as []).forEach(
          ({ comment, geometry: { x = 0, y = 0, width = 0, height = 0 } }) => {
            const { style } = new CellBuilder().create(ResourceConcept.Txt);
            const cell = graph.insertVertex(null, null, null, x, y, width, height, style);

            cell.mfm = new MfmModel();
            cell.mfm.conceptId = ResourceConcept.Txt;
            cell.mfm.comment = comment;
            cell.mfm.id = cell.getId().toString();
          },
        );
        graph.getModel().endUpdate();
      }),
      tap(() => console.log(logTime(), 'model import completed')),
      map(() => importActions.createGraphCompleted()),
    ),
  );

  public readonly importModelFromFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(modelBuilderAction.importModelFromFile),
        tap(() => {
          this.graphService.getGraph().getModel().clear();
        }),
      ),
    { dispatch: false },
  );

  public readonly updateViewportWhenImportCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(importActions.createGraphCompleted),
        concatLatestFrom(() => this.store.select(selectModelMetadata)),
        tap(([action, metadata]) => {
          const graph = this.graphService.getGraph();

          if (metadata.workbench?.viewport) {
            graph.zoomTo(metadata.workbench.viewport.scale ?? 1, false);
            graph.container.scrollLeft = metadata.workbench.viewport.x;
            graph.container.scrollTop = metadata.workbench.viewport.y;
          }
        }),
      ),
    { dispatch: false },
  );

  // When the model is imported and the graph is created,
  // we need to update the notes in the Store.
  // There is no information about the notes in the Store at this point,
  // all we've done is create the appropriate mxCells on the graph.
  // Therefore, we need to upsert them to the Store as well
  public readonly updateNotesWhenImportCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importActions.createGraphCompleted),
      map(() => {
        const notes = this.graphService
          .getCells()
          .filter(({ mfm }) => it(isTXT(() => mfm?.conceptId)))
          .map(({ id, mfm }) =>
            getDefaultConcept({ id: id.toString(), concept: mfm.conceptId, comment: mfm.comment }),
          );

        return modelBuilderAction.updateModel({ updates: notes });
      }),
    ),
  );

  /**
   * Process a graph items visibility
   */
  public readonly setVisibleMfmEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        // An action contains an `id` array of visible MFM concepts
        ofType(setVisibleMfmEntities),
        concatLatestFrom(() => this.store.select(selectMfmModel)),
        tap(([action, mfm]) => {
          const visible = new Set(action.ids);

          // Clear set of hidden items
          this.graphService.getGraph().hiddenCells.clear();
          // A graph contains items both that represent MFM concepts and service ones such as labels.
          // When passing a set of hidden items to the mxGraph only those
          // related to MFM should be taken into account.
          // That's why we're iterating through all MFM concepts
          // and collecting ones that are not visible (not included in `action.ids`).
          mfm.forEach(x => {
            if (visible.has(x.id) === false) {
              // MFM concept is hidden. Passing its `id` to the set of hidden cells
              this.graphService.getGraph().hiddenCells.add(x.id);
            }
          });
          this.graphService.getGraph().view.rendering = true;
          this.graphService.getGraph().refresh();
        }),
      ),
    { dispatch: false },
  );

  public readonly setZoomSystem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(graphZoomSystem),
        tap(() => {
          this.graphService.getGraph().zoomTo(zoomSystemLevel(), true);
        }),
      ),
    { dispatch: false },
  );

  public readonly toggleGridDisplay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleGridDisplay),
        tap(action => {
          const graph = this.graphService.getGraph();
          const graphView = graph.getView() as CustomGraphView;
          const bounds = graph.getGraphBounds();

          // Do nothing if the value is not changed
          if (graphView.isGridVisible() === action.visible) {
            return;
          }

          // Toggle the grid display
          graphView.setGridVisibility(action.visible);

          if (action.visible) {
            graphView.backgroundPageShape.node.style.backgroundImage =
              graphView.createBase64Image();
            graph.updatePageBreaks(true, bounds.width, bounds.height);
          } else {
            graphView.backgroundPageShape.node.style.backgroundImage = 'none';
            graph.updatePageBreaks(false, bounds.width, bounds.height);
          }
        }),
      ),
    { dispatch: false },
  );

  public readonly changeSelectionForReasoningPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reasoningAction.setSelectedPathIndex),
      concatLatestFrom(() => this.store.select(selectCurrentItem)),
      tap(([action, { functionId }]) => {
        const graph = this.graphService.getGraph();

        graph.getSelectionModel().clear();
        graph.getSelectionModel().addCell(graph.getModel().getCell(functionId));
      }),
      map(([action, { functionId: id }]) => focusGraphCell({ id })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly canvasTooltipService: CanvasTooltipService,
    private readonly clipboardService: ClipboardService,
    private readonly confirmationService: ConfirmationService,
    private readonly graphService: GraphService,
    private readonly notificationService: NotificationService,
    private readonly store: Store,
  ) {
    graphService
      .addGraphListener(mxEvent.FIRE_MOUSE_EVENT)
      .pipe(
        map(event => event.properties.event?.state?.cell.id ?? ''),
        distinctUntilChanged(),
      )
      .subscribe(id => {
        this.store.dispatch(graphCellPointed({ id }));
      });
    graphService.addGraphModelListener(mxEvent.CHANGE).subscribe(() => {
      this.store.dispatch(graphChanged());
    });
    graphService
      .addGraphSelectionModelListener(mxEvent.CHANGE)
      .pipe(
        concatLatestFrom(() =>
          this.store.select(selectMfmModel).pipe(map(model => toMap(model, 'id'))),
        ),
        map(([event, model]) =>
          this.graphService
            .getGraph()
            .getSelectionCells()
            .map(cell => String(cell.id))
            .filter(id => model.has(id)),
        ),
      )
      .subscribe(ids => {
        this.store.dispatch(modelBuilderAction.setSelectedMfmModelItems({ ids }));
      });
  }

  //#region Private members

  private deleteExtrasInterceptor(
    stashed: MfmConceptConfiguration,
    cascade: boolean,
    model: Map<string, MfmConceptConfiguration>,
  ): Observable<ConfirmationOption> {
    const graph = this.graphService.getGraph();
    const everything = (): true => true;
    const cells = cascade
      ? Array.from(
          graph
            .getSelectionCells()
            .reduce(
              // Collect all cells that descendant to selected cells (inclusive).
              // We use a `Map` to avoid duplications
              (descendants, parent) => (
                graph.model
                  .filterDescendants(everything, parent)
                  .forEach(x => descendants.set(x.getId(), x)),
                descendants
              ),
              new Map<string, mxgraph.mxCell>(),
            )
            .values(),
        )
      : graph.getSelectionCells();
    const intercept = cells.some(cell => it(exist(() => model.get(cell.id.toString())?.extras)));
    const confirmationData: ConfirmationData = {};

    if (intercept) {
      if (cells.length === 1) {
        if (it(exist(() => stashed))) {
          confirmationData.additionalInformation = `WARNING: You already have stashed data that will be overwritten`;
        }
        confirmationData.message = `You are about to remove the item that holds "extras". Do you really want to remove it together with "extras"?`;
        confirmationData.options = {
          [ConfirmationOption.Confirm]: 'Yes, remove it',
          [ConfirmationOption.Reject]: 'Remove and stash extras',
        };
      }
      if (cells.length > 1) {
        confirmationData.message = `You are about to remove the items while some of them hold "extras". Do you really want to remove it together with "extras"?`;
        confirmationData.options = {
          [ConfirmationOption.Confirm]: 'Yes, remove it',
        };
      }

      return this.confirmationService.confirm(confirmationData);
    }

    return of(ConfirmationOption.Confirm);
  }

  private graphDeleteSelectedCells(cascade: boolean): void {
    const graph = this.graphService.getGraph();
    const cells = graph.getDeletableCells(graph.getSelectionCells());

    if (it(exist(idt(cells)), () => cells.length > 0)) {
      graph.getModel().beginUpdate();
      if (cascade === false) {
        cells.forEach(cell => graph.removeCellsFromParent(graph.getModel().getChildCells(cell)));
      }

      if (cascade === false) {
        graph.removeCells(cells, false);
      } else {
        const structures = cells.filter(cell => it(isStructure(idt(cell.mfm?.conceptId))));
        const orphanEdges = findEdgesIsolatedInsideStructure(structures);

        graph.removeCells([...cells, ...orphanEdges], false);
      }

      // Selects parents for easier editing of groups
      const select = [];
      const parents = graph.model.getParents(cells) ?? [];

      parents
        .filter(p => graph.model.contains(p))
        .filter(p => graph.model.isVertex(p) || graph.model.isEdge(p))
        .forEach(p => select.push(p));

      graph.setSelectionCells(select);
      graph.getModel().endUpdate();
    }
  }

  private processProcessVariables(
    concepts: Map<string, MfmConceptConfiguration>,
    prev: Set<string>,
    next: Set<string>,
  ): void {
    const graph = this.graphService.getGraph();

    union(prev, next)
      .filter(id => (graph.getModel().getCell(id) ?? null) !== null)
      .forEach(id => {
        const cell = graph.getModel().getCell(id);

        if (
          graph.getModel().contains(cell) === true &&
          tryUpdateProcessVariableStyle(cell, concepts.get(id).processVariable) === true
        ) {
          // if the style has been changed, force redrawing of the cell's shape
          graph.view.validate();
          graph.cellRenderer.redraw(graph.getView().getState(cell));
        }
      });
  }

  private processSensors(
    prev: Map<string, Set<SensorStateValue>>,
    next: Map<string, Set<SensorStateValue>>,
  ): void {
    const graph = this.graphService.getGraph();

    union(new Set(prev.keys()), new Set(next.keys())).forEach(id => {
      const cell = graph.getModel().getCell(id);
      const sensorState = Array.from(next.get(id) ?? []);

      if (tryUpdateSensorStateStyle(cell, Array.from(sensorState)) === true) {
        // if the style has been changed, force redrawing of the cell's shape
        graph.view.validate();
        graph.cellRenderer.redraw(graph.getView().getState(cell));
      }
    });
  }

  private processActuators(prev: ModelActuators, next: ModelActuators): void {
    const graph = this.graphService.getGraph();

    new Set([...prev.all, ...next.all]).forEach(id => {
      const cell = graph.getModel().getCell(id);
      const value = getActuatorKindValue(
        next.automatic.has(id),
        next.manual.has(id),
        next.provisional.has(id),
      );

      if (tryUpdateActuatorStyle(cell, value)) {
        // if the style has been changed, force redrawing of the cell's shape
        graph.view.validate();
        graph.cellRenderer.redraw(graph.getView().getState(cell));
      }
    });
  }

  private processReliefSystems(prev: Map<string, string>, next: Map<string, string>): void {
    const graph = this.graphService.getGraph();

    union(new Set(prev.keys()), new Set(next.keys())).forEach(id => {
      const cell = graph.getModel().getCell(id);

      if (tryUpdateReliefSystemStyle(cell, next.has(id))) {
        // if the style has been changed, force redrawing of the cell's shape
        graph.view.validate();
        graph.cellRenderer.redraw(graph.getView().getState(cell));
      }
    });
  }

  private processExposedFunctions(prev: Set<string>, next: Set<string>): void {
    const graph = this.graphService.getGraph();

    union(prev, next)
      .filter(id => (graph.getModel().getCell(id) ?? null) !== null)
      .forEach(id => {
        const cell = graph.getModel().getCell(id);

        if (tryUpdateExposedStyle(cell, next.has(id)) === true) {
          // if the style has been changed, force redrawing of the cell's shape
          graph.view.validate();
          graph.cellRenderer.redraw(graph.getView().getState(cell));
        }
      });
  }

  private processSubModels(
    prev: Map<string, MfmConceptConfiguration>,
    next: Map<string, MfmConceptConfiguration>,
  ): void {
    const graph = this.graphService.getGraph();

    for (const { id, subModelTerminals: nextTerminals } of next.values()) {
      const subModelCell = graph.model.getCell(id);
      const previousTerminals = prev.get(id)?.subModelTerminals ?? null;

      if (
        tryUpdateSubModel(graph, subModelCell, { previous: previousTerminals, next: nextTerminals })
      ) {
        graph.refresh(subModelCell);
      }
    }
  }

  private highlightCausalTree(
    causalTree: CausalAnalysisPropagationPath[],
    model: Map<string, MfmConceptConfiguration>,
    include?: Partial<{
      fault: { functionId: string; fault: ReasoningFaultKind };
      causes: CausalAnalysisPropagationPathNode[];
      consequences: CausalAnalysisPropagationPathNode[];
      inspectionPoints: true;
      preventiveActions: true;
      displayState: true;
    }>,
  ): void {
    const shouldIncludeInspectionPoints: Predicate = () => include?.inspectionPoints === true;
    const shouldIncludePreventiveActions: Predicate = () => include?.preventiveActions === true;
    const shouldShowNodeStates: Predicate = () => include?.displayState === true;

    const graph = this.graphService.getGraph();
    const graphModel = graph.getModel();

    graph.resetAllCustomStyles();

    graphModel.beginUpdate();

    if (include?.fault) {
      graph.patchVertexCustomStyles(include.fault?.functionId, {
        fill: faultColor(),
        outline: faultOutlineColor(),
        shape: getShape(include.fault.fault, model.get(include.fault.functionId).concept),
      });
    }

    if (include?.causes?.length > 0) {
      include.causes
        .filter(({ functionId }) => model.has(functionId))
        .forEach(node => {
          graph.patchVertexCustomStyles(node.functionId, {
            fill: rootCauseColor(),
            shape: getShape(node.state, model.get(node.functionId).concept),
          });
        });
    }

    if (include?.consequences?.length > 0) {
      include.consequences
        .filter(({ functionId }) => model.has(functionId))
        .forEach(node => {
          graph.patchVertexCustomStyles(node.functionId, {
            fill: endConsequenceColor(),
            shape: getShape(node.state, model.get(node.functionId).concept),
          });
        });
    }

    causalTree.forEach(path => {
      // A propagation path - from the cause to the consequence
      const pathVector = [
        path.rootCause.functionId,
        ...path.nodes.map(x => x.functionId),
        path.endConsequence.functionId,
      ];
      const pathSet = new Set(pathVector);
      // All edges that touch the vertices on the propagation path
      const edges = Array.from(model.values())
        .filter(x => it(isRelation(getConcept(x))))
        .filter(edge =>
          it(
            or(
              () => pathSet.has(getNotEmpty(edge.mainFunction?.sourceId, edge.linkSourceId)),
              () => pathSet.has(getNotEmpty(edge.mainFunction?.targetId, edge.linkTargetId)),
            ),
          ),
        );

      // Highlight root-cause and end-consequence cells.
      // In case it is the "Fault" function, the priority is given to the "fault case" highlight
      if (path.rootCause.functionId !== include?.fault?.functionId) {
        graph.updateVertexCustomStyles(path.rootCause.functionId, {
          fill: rootCauseColor(),
          outline: rootCauseOutlineColor(),
          shape: getShape(path.rootCause.state, model.get(path.rootCause.functionId).concept),
        });
      }
      if (path.endConsequence.functionId !== include?.fault?.functionId) {
        graph.updateVertexCustomStyles(path.endConsequence.functionId, {
          fill: endConsequenceColor(),
          outline: endConsequenceOutlineColor(),
          shape: getShape(
            path.endConsequence.state,
            model.get(path.endConsequence.functionId).concept,
          ),
        });
      }

      // Get all Actuator relations
      const actuators = Array.from(model.values()).filter(x => it(isAC(getConcept(x))));

      // Highlight intermediate vertices
      path.nodes.forEach(node => {
        const mfm = model.get(node.functionId);

        // Inspection Points
        if (it(shouldIncludeInspectionPoints, isOfflineProcessVariable(mfm?.processVariable))) {
          graph.patchVertexCustomStyles(node.functionId, { fill: inspectionPointColor() });
        }
        // Preventive actions
        if (it(shouldIncludePreventiveActions, isPreventiveActionCandidate(mfm, actuators))) {
          graph.patchVertexCustomStyles(node.functionId, { fill: preventiveColor() });
        }
        // Shapes for nodes in path
        graph.patchVertexCustomStyles(
          node.functionId,
          it(shouldShowNodeStates)
            ? { shape: getShape(node.state, model.get(node.functionId).concept) }
            : {},
        );
      });

      // Iterate through the path of propagation vertex by vertex
      pathVector.forEach((point, index, points) => {
        // Skip the first point of the route
        if (index > 0) {
          // The ID of the previous Concept
          const previous = model.get(points[index - 1])?.id;
          // The ID of the current Concept
          const current = model.get(point)?.id;
          // Straight connection between two Concepts
          const connection = edges.find(x => it(isConnected(x, current, previous)));

          // Highlight the edge if connection found
          if (it(exist(() => connection))) {
            graph.patchEdgeCustomStyles(
              connection.id,
              arrowColor(),
              it(hasOppositeDirection(connection, previous)),
            );
          }

          // Try to find the connection between two Concepts through the common vertex
          if (it(not(exist(() => connection)))) {
            const pEdges = edges
              .filter(x => it(isConnected(x, previous)))
              .map(x => getOppositeEnd(x, previous));
            const cEdges = edges
              .filter(x => it(isConnected(x, current)))
              .map(x => getOppositeEnd(x, current));
            // The common vertex
            const commonVertex = pEdges.find(x => cEdges.includes(x));
            // Find relations: previous -> commonVertex -> current
            const a = edges.find(e => it(isConnected(e, previous, commonVertex)));
            const b = edges.find(e => it(isConnected(e, current, commonVertex)));

            graph.patchEdgeCustomStyles(a.id, arrowColor(), it(hasOppositeDirection(a, previous)));
            graph.patchEdgeCustomStyles(
              b.id,
              arrowColor(),
              it(hasOppositeDirection(b, commonVertex)),
            );
          }
        }
      });
    });

    graphModel.endUpdate();
  }
  //#endregion
}

//#region Utility Functions

const findEdgesIsolatedInsideStructure = (structures: mxgraph.mxCell[]): mxgraph.mxCell[] =>
  structures
    // Process only the Structure that has children
    .filter(s => s.children)
    .reduce((allOrphans, structure) => {
      // Get all vertices that have at least one connected edge
      const vertices = structure.children.filter(c => c.vertex && c.edges && c.edges.length);
      // Get all unique edges
      const edges = vertices.reduce(
        (edgesMap, vertex) => (
          // Use a Map with id as a key and mxCell itself as a value.
          // Add every edge into the Map
          vertex.edges.forEach(e => edgesMap.set(e.id, e)), edgesMap
        ),
        new Map<string, mxgraph.mxCell>(),
      );
      // Extract all edges connected to the vertices inside the particular Structure from both ends.
      // After we remove the structure and all vertices that are children of this structure,
      // these edges become 'orphans'.
      // We need to remove them too
      const orphans = Array.from(edges)
        // Get array of unique edges from the Map
        .map(([id, edge]) => edge)
        // Leave the only edge meets the condition: remains as an 'orphan' after removing the Structure.
        // Count the number of connections for every edge and filter them
        .filter(
          edge =>
            vertices.reduce(
              (numOfConnections, vertex) =>
                vertex.edges.findIndex(e => e.id === edge.id) !== -1
                  ? numOfConnections + 1
                  : numOfConnections,
              0,
            ) > 1,
        );

      // Concatenate orphans from every structure into one result
      return [...allOrphans, ...orphans];
    }, [] as mxgraph.mxCell[]);

const getConcept =
  (mfm: MfmConceptConfiguration): (() => ResourceConcept) =>
  (): ResourceConcept =>
    mfm.concept;

const getActuatorKindValue = (
  isAutomatic: boolean,
  isManual: boolean,
  isProvisional: boolean,
): ActuatorKindStyle => {
  if (isAutomatic === true) {
    return ActuatorKindStyle.Automatic;
  }
  if (isManual === true) {
    return ActuatorKindStyle.Manual;
  }
  if (isProvisional === true) {
    return ActuatorKindStyle.Provisional;
  }

  return ActuatorKindStyle.None;
};

// prettier-ignore
const getHazardLevelValue = (level: MfmConceptConfiguration['level']): number => {
  switch (level) {
    case SeverityLevel.Low: return 1;
    case SeverityLevel.Trip: return 2;
    default: return;
  }
};

// prettier-ignore
const getObjectiveCategoryValue = (category: MfmConceptConfiguration['category']): number => {
  switch (category) {
    case 'safety': return 0;
    case 'production': return 1;
    case 'emissions': return 2;
    default: return;
  }
};

// prettier-ignore
const getProcessVariableValue = (processVariable: MfmConceptConfiguration['processVariable']): string => {
  if (it(isProcessVariable(processVariable))) {
    return String(processVariable);
  }

  return '';
};

const getSensorStateValue = (states: SensorStateValue[]): string => states.sort().join('\u2022');

const isPreventiveActionCandidate = (
  mfm: MfmConceptConfiguration,
  actuators: MfmConceptConfiguration[],
): Predicate => and(isTRA(getConcept(mfm)), isTargetOfActuator(mfm.id, actuators));

const isTargetOfActuator =
  (id: string, model: MfmConceptConfiguration[]): Predicate =>
  (): boolean =>
    model.some(element => getNotEmpty(element.mainFunction?.targetId, element.linkTargetId) === id);

const tryUpdateActuatorStyle = (cell: mxgraph.mxCell, kind: ActuatorKindStyle): boolean => {
  if ((cell ?? null) === null) {
    return false;
  }

  const previousValue = getStyle(cell.getStyle(), styleActuator());

  if (kind === ActuatorKindStyle.None && previousValue !== '') {
    cell.setStyle(removeStyle(cell.style, styleActuator()));

    return true;
  }
  cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleActuator(), String(kind)));

  return true;
};

const tryUpdateConcept = (
  cell: mxgraph.mxCell,
  concept: MfmConceptConfiguration['concept'],
): boolean => {
  if (concept !== cell.mfm.conceptId) {
    // prettier-ignore
    // Remove styles that are related to the shape of the concept
    cell.setStyle(removeStyle(cell.style, mxConstants.STYLE_ENDARROW, mxConstants.STYLE_ENDFILL, mxConstants.STYLE_ENDSIZE, mxConstants.STYLE_STARTARROW, mxConstants.STYLE_STARTFILL, mxConstants.STYLE_STARTSIZE, mxConstants.STYLE_SHAPE, mxConstants.STYLE_STROKEWIDTH, mxConstants.STYLE_PERIMETER, mxConstants.STYLE_RESIZABLE, mxConstants.STYLE_VERTICAL_LABEL_POSITION, mxConstants.STYLE_VERTICAL_ALIGN));
    // Update the cell's style with a new style
    // which has been merged with the style that is specific to the new concept
    cell.setStyle(assignStyle(cell.style, new CellBuilder().create(concept).style));
    cell.mfm.conceptId = concept;

    return true;
  }

  return false;
};

const tryUpdateHazardLevelStyle = (
  cell: mxgraph.mxCell,
  level: MfmConceptConfiguration['level'],
): boolean => {
  const previousValue = getStyle(cell.getStyle(), styleHazardLevel());
  const value = String(getHazardLevelValue(level) ?? '');

  cell.setStyle(removeStyle(cell.style, styleHazardLevel()));
  if (value !== '') {
    cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleHazardLevel(), value));
  }

  return value !== previousValue;
};

const tryUpdateLabel = (cell: mxgraph.mxCell, mfm: MfmConceptConfiguration): boolean => {
  const previousValue = cell.getValue();
  const newValue = getConceptLabel(mfm);

  return (cell.setValue(newValue), cell.getValue()) !== previousValue;
};

const tryUpdateSecondaryLabel = (
  cell: mxgraph.mxCell,
  source: MfmConceptConfiguration | null,
  target: MfmConceptConfiguration | null,
): boolean => {
  const prev = cell.getChildAt(0).getValue();

  if (source) {
    if (source.label !== '') {
      cell.getChildAt(0).setValue(`${source.label} (${source.concept})`);
    } else {
      cell.getChildAt(0).setValue(source.concept);
    }
  }
  if (target) {
    if (target.label !== '') {
      cell.getChildAt(0).setValue(`${target.label} (${target.concept})`);
    } else {
      cell.getChildAt(0).setValue(target.concept);
    }
  }

  return cell.getChildAt(0).getValue() !== prev;
};

const tryUpdateLogicGateValue = (
  cell: mxgraph.mxCell,
  logicGateVotes: MfmConceptConfiguration['logicGateVotes'],
): boolean => {
  const previousValue = getStyle(cell.getStyle(), styleLogicalGateValue());
  const value = (logicGateVotes ?? '').toString();

  cell.setStyle(removeStyle(cell.style, styleLogicalGateValue()));
  if (value !== '') {
    cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleLogicalGateValue(), value));
  }

  return value !== previousValue;
};

const tryUpdateObjectiveCategoryStyle = (
  cell: mxgraph.mxCell,
  category: MfmConceptConfiguration['category'],
): boolean => {
  const previousValue = getStyle(cell.getStyle(), styleObjectiveCategory());
  const value = (getObjectiveCategoryValue(category) ?? '').toString();

  cell.setStyle(removeStyle(cell.style, styleObjectiveCategory()));
  if (value !== '') {
    cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleObjectiveCategory(), value));
  }

  return value !== previousValue;
};

const tryUpdateProcessVariableStyle = (
  cell: mxgraph.mxCell,
  processVariable: MfmConceptConfiguration['processVariable'],
): boolean => {
  const previousValue = getStyle(cell.getStyle(), styleProcessVariable());
  const value = getProcessVariableValue(processVariable);

  cell.setStyle(removeStyle(cell.style, styleProcessVariable()));
  if (value !== '') {
    cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleProcessVariable(), value));
  }

  return value !== previousValue;
};

const tryUpdateReliefSystemStyle = (cell: mxgraph.mxCell, on: boolean): boolean => {
  if ((cell ?? null) === null) {
    return false;
  }

  const previousValue = getStyle(cell.getStyle(), styleReliefSystem());

  cell.setStyle(removeStyle(cell.style, styleReliefSystem()));
  if (on === true) {
    cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleReliefSystem(), 1));
  }

  return on !== (previousValue === '1');
};

const tryUpdateSensorStateStyle = (cell: mxgraph.mxCell, states: SensorStateValue[]): boolean => {
  if ((cell ?? null) === null) {
    return false;
  }

  const previousValue = getStyle(cell.getStyle(), styleSensorState());
  const value = getSensorStateValue(states).toString();

  cell.setStyle(removeStyle(cell.style, styleSensorState()));
  if (value !== '') {
    cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleSensorState(), value));
  }

  return value !== previousValue;
};

const tryUpdateSubModel = (
  graph: mxgraph.mxGraph,
  cell: mxgraph.mxCell,
  terminals: Partial<{
    previous: MfmConceptConfiguration['subModelTerminals'];
    next: MfmConceptConfiguration['subModelTerminals'];
  }>,
): boolean => {
  if (cell) {
    return updateSubModel(graph, cell, terminals);
  }

  return false;
};

const tryUpdateExposedStyle = (cell: mxgraph.mxCell, exposed: boolean): boolean => {
  if ((cell ?? null) === null) {
    return false;
  }

  const previousValue = getStyle(cell.getStyle(), styleExposed());
  const value = exposed === true ? '1' : '';

  if (value !== previousValue) {
    cell.setStyle(mxUtils.setStyle(cell.getStyle(), styleExposed(), value));
  }

  return value !== previousValue;
};
//#endregion
